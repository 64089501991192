import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  SelectChangeEvent,
} from '@mui/material';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { Weight } from '../../types/customerTypes';

interface WeightChartProps {
  data: Weight[]; // all available weight points
}

/** Possible time frames the user can select. */
type TimeFrame = 'LAST_3_MONTHS' | 'LAST_6_MONTHS' | 'LAST_YEAR' | 'ALL';

const WeightChart: React.FC<WeightChartProps> = ({ data }) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  // 1. State for selected timeframe (default = last 6 months)
  const [timeFrame, setTimeFrame] = useState<TimeFrame>('LAST_YEAR');

  // 2. Handler for <Select> changes
  const handleTimeFrameChange = (event: SelectChangeEvent<TimeFrame>) => {
    setTimeFrame(event.target.value as TimeFrame);
  };

  // 3. Compute the date range based on selected timeframe
  const filteredData = useMemo(() => {
    if (!data || data.length === 0) return [];

    const now = new Date();
    let startDate: Date;

    switch (timeFrame) {
      case 'LAST_3_MONTHS':
        startDate = new Date(
          now.getFullYear(),
          now.getMonth() - 3,
          now.getDate()
        );
        break;
      case 'LAST_6_MONTHS':
        startDate = new Date(
          now.getFullYear(),
          now.getMonth() - 6,
          now.getDate()
        );
        break;
      case 'LAST_YEAR':
        startDate = new Date(
          now.getFullYear() - 1,
          now.getMonth(),
          now.getDate()
        );
        break;
      case 'ALL':
      default:
        // If ALL, no filter needed
        return data.slice().sort((a, b) => a.date.getTime() - b.date.getTime());
    }

    // filter only points from startDate to now
    return data
      .filter((w) => w.date >= startDate && w.date <= now)
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [data, timeFrame]);

  // 4. Convert Date => string for Recharts
  const chartData = useMemo(() => {
    return filteredData.map((w) => ({
      date: w.date.toISOString(),
      weight: w.weight,
    }));
  }, [filteredData]);

  return (
    <Card
      sx={{
        padding: 2,
        border: '1px solid #E0E0E0',
        borderRadius: 6,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      {/* Title & Time Frame Select in the same row */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'stretch' : 'center',
          justifyContent: 'space-between',
          gap: 2,
          mb: 2,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Weight History
        </Typography>

        {/* TimeFrame Select */}
        <FormControl
          sx={{
            minWidth: 180,
          }}
        >
          <InputLabel id="timeframe-select-label">Time Frame</InputLabel>
          <Select
            labelId="timeframe-select-label"
            value={timeFrame}
            label="Time Frame"
            onChange={handleTimeFrameChange}
          >
            <MenuItem value="LAST_3_MONTHS">Last 3 Months</MenuItem>
            <MenuItem value="LAST_6_MONTHS">Last 6 Months</MenuItem>
            <MenuItem value="LAST_YEAR">Last Year</MenuItem>
            <MenuItem value="ALL">All</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Chart Container (max height = 215px) */}
      <Box
        sx={{
          width: '100%',
          height: '215px', // fixed height
          maxHeight: '215px',
          overflow: 'hidden', // ensure no extra space
        }}
      >
        {chartData.length === 0 ? (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color="textSecondary">
              No data in this timeframe.
            </Typography>
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 10, right: 10, left: -25, bottom: 10 }}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric',
                  });
                }}
              />
              <YAxis dataKey="weight" />
              <Tooltip
                labelFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  });
                }}
                formatter={(value, name) => {
                  if (name === 'weight') {
                    return `${value} kg`;
                  }
                  return value;
                }}
              />
              <Line
                type="monotone" // or "natural"
                dataKey="weight"
                stroke="#8884d8"
                strokeWidth={2}
                dot={{ r: 5 }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Card>
  );
};

export default WeightChart;

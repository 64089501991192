import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Alert,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { supabase } from '../../supabaseClient';
import { REALTIME_SUBSCRIBE_STATES } from '@supabase/realtime-js';
import { JournalTextEntry } from '../../types/journalTypes';
import JournalTextEntryContainer from '../JournalTextEntryContainer';
import CloseIcon from '@mui/icons-material/Close';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ArrowDownward } from '@mui/icons-material';

interface JournalInputProps {
  userId: string;
  journalId: string;
}

/**
 * A UI for "publishing" + editing text entries to a journal using Quill.
 * - Real-time subscription for new/updated entries
 * - Edits are detected by comparing old HTML vs new HTML (so style changes count)
 */
const JournalInput: React.FC<JournalInputProps> = ({ userId, journalId }) => {
  const [entries, setEntries] = useState<JournalTextEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  // For the Quill editor value
  const [quillValue, setQuillValue] = useState(''); // this will be the *HTML*
  const [deltaValue, setDeltaValue] = useState<any>(null); // this will be the *DELTA*

  // For editing:
  const [editingEntry, setEditingEntry] = useState<JournalTextEntry | null>(
    null
  );

  // State for tracking whether the current action is a "publish"
  const [isPublishing, setIsPublishing] = useState(false);

  // For the "New entry" button
  const [showNewEntriesButton, setShowNewEntriesButton] = useState(false);

  // Publishing board ref
  const publishingBoardRef = useRef<HTMLDivElement | null>(null);

  // ---------------------------
  // 0) Track entries in a `useEffect` to auto-scroll when `isPublishing` is true
  // ---------------------------
  useEffect(() => {
    if (isPublishing && publishingBoardRef.current) {
      setTimeout(() => {
        publishingBoardRef.current!.scrollTo({
          top: publishingBoardRef.current!.scrollHeight,
          behavior: 'smooth',
        });
        setIsPublishing(false); // Reset after scrolling
      }, 100); // Small delay for rendering
    }
  }, [isPublishing]);

  // ---------------------------
  // 1) Fetch existing entries
  // ---------------------------
  useEffect(() => {
    const fetchEntries = async () => {
      try {
        setLoading(true);
        setErrorMsg(null);

        const { data, error } = await supabase
          .from('journal_entries')
          .select(
            `
            *,
            user_profiles (
              name,
              surname
            )
          `
          )
          .eq('journal_id', journalId)
          .order('text_entry_id', { ascending: true }) // Group by text_entry_id
          .order('created_at', { ascending: false }); // Pick the latest created_at

        if (error) {
          throw error;
        }
        if (data) {
          // Filter to get the latest version per text_entry_id
          const latestEntries = data.reduce(
            (acc: Record<number, any>, entry: any) => {
              const { text_entry_id } = entry;
              if (
                !acc[text_entry_id] ||
                acc[text_entry_id].created_at < entry.created_at
              ) {
                acc[text_entry_id] = entry; // Keep only the latest entry
              }
              return acc;
            },
            {}
          );

          // Map filtered entries to include user details
          const formattedEntries = Object.values(latestEntries).map(
            (entry: any) => ({
              ...entry,
              createdByName: entry.user_profiles
                ? `${entry.user_profiles.name} ${entry.user_profiles.surname}`
                : 'Unknown',
            })
          );

          // Sort the final entries by index_order
          const sortedEntries = formattedEntries.sort(
            (a: any, b: any) => a.index_order - b.index_order
          );

          setEntries(sortedEntries as JournalTextEntry[]);
        }
      } catch (err: any) {
        console.error('Error fetching entries:', err);
        setErrorMsg(err.message || 'Failed to load entries');
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, [journalId]);

  // ---------------------------
  // 2) Real-time subscription
  // ---------------------------
  useEffect(() => {
    // Create a real-time channel for the journal entries table
    const channel = supabase
      .channel('journal-entries-channel')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'journal_entries',
          filter: `journal_id=eq.${journalId}`,
        },
        (payload) => {
          console.log('New entry inserted:', payload.new);
          const newEntry = payload.new as JournalTextEntry;

          setEntries((prev) => {
            // 1) Check if text_entry_id is brand new or previously existed
            const alreadyExisted = prev.some(
              (entry) => entry.text_entry_id === newEntry.text_entry_id
            );

            // 2) We want to replace any older version with this newer one
            //    or add if it's brand new
            const updated = [
              ...prev.filter((e) => e.text_entry_id !== newEntry.text_entry_id),
              newEntry,
            ];

            // 3) sort if needed
            updated.sort((a, b) => a.index_order - b.index_order);

            // 4) If user is near bottom => auto-scroll
            if (isNearBottom()) {
              scrollToBottom();
            } else {
              // 5) If this is truly a new text_entry_id, show the button
              if (!alreadyExisted) {
                setShowNewEntriesButton(true);
              }
            }

            return updated;
          });
        }
      )
      .subscribe((status) => {
        if (status === REALTIME_SUBSCRIBE_STATES.SUBSCRIBED) {
          console.log('Subscription to journal entries successful.');
        } else {
          console.error('Subscription status:', status);
        }
      });

    return () => {
      supabase.removeChannel(channel);
    };
  }, [journalId]);

  // --------------------------------
  // 3) handlePublish => Insert
  // --------------------------------
  const handlePublish = async () => {
    try {
      setErrorMsg(null);

      // realDelta is stored in our "deltaValue" state
      // quillValue is your HTML
      // plainText is a textual fallback
      const realDelta = deltaValue || { ops: [] }; // fallback if somehow null

      const { data, error } = await supabase
        .from('journal_entries')
        .insert([
          {
            journal_id: journalId,
            // store your HTML in 'rendered_text'
            rendered_text: quillValue,
            // store the actual Quill delta
            delta: realDelta,
            status_id: 1,
            created_by: userId,
            editable: true,
          },
        ])
        .select('*');

      if (error) throw error;

      console.log('Insert success =>', data);
      // Clear Quill
      setQuillValue('');
      setDeltaValue(null);

      // Trigger auto-scroll for publishing
      setIsPublishing(true);
    } catch (err: any) {
      console.error('Failed to publish entry:', err);
      setErrorMsg(err.message || 'Failed to publish entry');
    }
  };

  // Helper function: Check if user is near bottom
  const isNearBottom = () => {
    const board = publishingBoardRef.current;
    if (!board) return false;

    const threshold = 50; // e.g., 50px from bottom is "near"
    const scrollPosition = board.scrollTop + board.clientHeight;
    const scrollHeight = board.scrollHeight;

    return scrollHeight - scrollPosition <= threshold;
  };

  // Helper: scroll to bottom
  const scrollToBottom = () => {
    if (publishingBoardRef.current) {
      publishingBoardRef.current.scrollTo({
        top: publishingBoardRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    setShowNewEntriesButton(false);
  };

  // Listen for user scrolling => if user goes to bottom, hide new-entry button
  const handleBoardScroll = () => {
    if (showNewEntriesButton && isNearBottom()) {
      setShowNewEntriesButton(false);
    }
  };

  // ------------------------
  // 4) handleEdit => Load the HTML
  // ------------------------
  const handleEdit = (entry: JournalTextEntry) => {
    if (!entry.editable) {
      console.warn('Entry is not editable.');
      return;
    }
    setEditingEntry(entry);
    // load its rendered_text (HTML)
    setQuillValue(entry.rendered_text || '');
    // restore delta
    setDeltaValue(entry.delta || { ops: [] });
  };

  // 5) Cancel editing
  const handleCancelEdit = () => {
    setEditingEntry(null);
    setQuillValue('');
    setDeltaValue(null);
  };

  // ------------------------
  // 6) handleSave => Update
  // ------------------------
  const handleSave = async () => {
    if (!editingEntry) return;

    // Check if quillValue changed significantly (including style changes).
    // We do a direct string compare on the old vs new HTML.
    const oldHtml = (editingEntry.rendered_text || '').trim();
    const newHtml = quillValue.trim();

    // If no difference, skip.
    if (newHtml === oldHtml) {
      return; // no style or text changes
    }

    try {
      setErrorMsg(null);

      const realDelta = deltaValue || { ops: [] };

      // Insert a new version of the entry.
      const { data, error } = await supabase
        .from('journal_entries')
        .insert([
          {
            index_order: editingEntry.index_order, // Keep the same index_order.
            text_entry_id: editingEntry.text_entry_id, // Keep the same text_entry_id.
            journal_id: editingEntry.journal_id, // Keep the same journal_id.
            rendered_text: quillValue, // The new content.
            delta: realDelta, // The Quill delta.
            created_by: userId, // The current user.
            status_id: 1, // Example: default status.
            editable: true, // Make the new entry editable by default.
          },
        ])
        .select('*');

      if (error) throw error;

      console.log('Inserted new version:', data);
      setEditingEntry(null);
      setQuillValue('');
      setDeltaValue(null);
    } catch (err: any) {
      console.error('Failed to save new version:', err);
      setErrorMsg(err.message || 'Failed to save new version');
    }
  };

  // The main button label & onClick
  const isEditing = !!editingEntry;
  const buttonLabel = isEditing ? 'Save' : 'Publish';
  const handleButtonClick = isEditing ? handleSave : handlePublish;

  // ------------------------
  // Checking if Save/Publish button is disabled
  // ------------------------
  let isButtonDisabled = false;
  // if quillValue has no plain text at all
  const currentPlain = quillValue.replace(/<[^>]+>/g, '').trim();
  if (!currentPlain) {
    // empty
    isButtonDisabled = true;
  } else if (isEditing && editingEntry) {
    // Compare entire HTML for style changes
    const oldHtml = (editingEntry.rendered_text || '').trim();
    const newHtml = quillValue.trim();
    if (oldHtml === newHtml) {
      // no difference in HTML => disable
      isButtonDisabled = true;
    }
  }

  // 7) A short snippet of the text for the black bar
  const snippetForEditing = isEditing
    ? // Strip HTML tags => replace line breaks with spaces => get the first 30 characters => add ...
      (
        quillValue
          .replace(/<br\s*\/?>|<\/p>|\n/g, ' ') // Replace <br>, </p>, or \n with a single space
          .replace(/<[^>]+>/g, '') // Remove remaining HTML tags
          .substring(0, 30) || ''
      ) // Get the first 30 characters
        .trim() + '...'
    : '';
  return (
    <Box
      sx={{
        paddingBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        background: '#FAFAFA',
      }}
    >
      {/* Optional error display */}
      {errorMsg && (
        <Alert severity="error" onClose={() => setErrorMsg(null)}>
          {errorMsg}
        </Alert>
      )}

      {/* If editing => show black info bar */}
      {isEditing && editingEntry && (
        <Box
          sx={{
            backgroundColor: 'black',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2">
            You are editing: &quot;{snippetForEditing}&quot;
          </Typography>
          <IconButton
            onClick={handleCancelEdit}
            size="small"
            sx={{ color: 'white' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      {/* 1) Publishing Board (scrollable) */}
      <Box
        ref={publishingBoardRef}
        sx={{
          paddingX: 2,
          paddingY: 1,
          flex: 1, // so it grows
          overflowY: 'auto', // scroll
          display: 'flex',
          flexDirection: 'column',
        }}
        onScroll={handleBoardScroll}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : entries.length === 0 ? (
          <Typography color="text.secondary" sx={{ fontStyle: 'italic' }}>
            No entries yet.
          </Typography>
        ) : (
          entries.map((entry) => (
            <JournalTextEntryContainer
              key={entry.id}
              entry={entry}
              onEdit={() => handleEdit(entry)}
            />
          ))
        )}

        {/* If showNewEntriesButton => display "New Entry" button */}
        {showNewEntriesButton && !isEditing && (
          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: 12,
              position: 'sticky',
              alignSelf: 'center',
              bottom: 10,
              marginRight: 2,
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: '#333', // Slightly lighter black for hover
              },
              display: 'flex',
              alignItems: 'center',
              gap: 1, // Space between text and icons
            }}
            onClick={scrollToBottom}
          >
            <ArrowDownward />
            New Entry
            <ArrowDownward />
          </Button>
        )}
      </Box>

      {/* Quill Editor + Publish/Save Button */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <ReactQuill
          theme="snow"
          value={quillValue}
          onChange={(content, delta, source, editor) => {
            // content = HTML string
            // delta = Quill's delta object
            setQuillValue(content);
            const fullDocDelta = editor.getContents(); // entire doc as a Quill Delta
            setDeltaValue(fullDocDelta); // store the real delta
          }}
          // minimal modules or default
        />
        <Button
          variant="contained"
          disabled={isButtonDisabled}
          onClick={handleButtonClick}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default JournalInput;

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  Box,
  IconButton,
} from '@mui/material';
import { CustomerBasic } from '../../types/customerTypes';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface CustomerTableProps {
  data: CustomerBasic[];
}

const CustomerTable: React.FC<CustomerTableProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 25;
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    navigate(`/app/customer-book/${id}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const paginatedData = data.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2, // 16px border radius
        overflowX: isMobile ? 'visible' : 'auto', // Enable scrolling in desktop mode
      }}
    >
      {isMobile ? (
        // Mobile View: Single-column layout
        <Table>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No customers available 🐕‍🦺
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((customer) => {
                const fullName = `${customer.name} ${customer.surname}`;
                const petNames =
                  customer.pets && customer.pets.length > 0
                    ? customer.pets
                        .slice(0, 2) // Take only the first two pets
                        .map((pet) => pet.name)
                        .join(', ') + (customer.pets.length > 2 ? ', ...' : '') // Add "..." if more than two pets
                    : 'No Pets 🐕‍🦺';

                return (
                  <TableRow
                    key={customer.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    onClick={() => handleRowClick(customer.id)}
                  >
                    <TableCell>
                      <Typography>
                        <strong>Name:</strong> {fullName}
                      </Typography>
                      <Typography>
                        <strong>Email:</strong> {customer.email}
                      </Typography>
                      <Typography>
                        <strong>Phone Number:</strong> {customer.phoneNumber}
                      </Typography>
                      <Typography>
                        <strong>Pets:</strong> {petNames}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      ) : (
        // Desktop View: Scrollable table
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
              <TableCell sx={{ width: '25%' }}>
                <strong>Name</strong>
              </TableCell>
              <TableCell sx={{ width: '25%' }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ width: '25%' }}>
                <strong>Phone Number</strong>
              </TableCell>
              <TableCell sx={{ width: '25%' }}>
                <strong>Pets</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No customers available 🐕‍🦺
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((customer) => {
                const fullName = `${customer.name} ${customer.surname}`;
                const petNames =
                  customer.pets && customer.pets.length > 0
                    ? customer.pets
                        .slice(0, 2) // Take only the first two pets
                        .map((pet) => pet.name)
                        .join(', ') + (customer.pets.length > 2 ? ', ...' : '') // Add "..." if more than two pets
                    : 'No Pets 🐕‍🦺';

                return (
                  <TableRow
                    key={customer.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    onClick={() => handleRowClick(customer.id)}
                  >
                    <TableCell>{fullName}</TableCell>
                    <TableCell>{customer.email}</TableCell>
                    <TableCell>{customer.phoneNumber}</TableCell>
                    <TableCell>{petNames}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <IconButton
            onClick={handlePrevPage}
            disabled={currentPage === 0}
            aria-label="Previous Page"
          >
            <ChevronLeft />
          </IconButton>
          <Typography>
            Page {currentPage + 1} of {totalPages}
          </Typography>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
            aria-label="Next Page"
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
    </TableContainer>
  );
};

export default CustomerTable;

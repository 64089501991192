import i18nIsoCountries from '../services/i18Countries';
import { phoneCountryList } from '../services/phoneCountries';

export function toIsoDateString(date: Date): string {
  // Format "YYYY-MM-DD"
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getCountryName(isoCode?: string): string {
  if (!isoCode) return 'N/A';
  const name = i18nIsoCountries.getName(isoCode, 'en', { select: 'official' });
  return name || isoCode; // fallback to isoCode if not found
}

export function getPhoneDialCode(iso?: string): string {
  if (!iso) return '';
  const found = phoneCountryList.find((c) => c.iso === iso);
  return found?.dial_code || '';
}

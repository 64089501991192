import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PublicLayout from './components/PublicLayout';
import AppLayout from './components/AppLayout';
import ProtectedRoute from './utils/ProtectedRoute';

import DemoPage from './pages/public/DemoPage';
import SignIn from './pages/public/SignIn';
import CreateAccount from './pages/public/CreateAccount';
import ForgotPasswordPage from './pages/public/SignIn/ForgotPassword';
import ResetPasswordPage from './pages/public/SignIn/ResetPasswrod';

import HomePage from './pages/app/Home';
import PetDirectoryPage from './pages/app/PetDirectory';
import PetProfile from './pages/app/PetProfile';
import CustomerBookPage from './pages/app/CustomerBook';
import CustomerProfile from './pages/app/CustomerProfile';
import JournalsPage from './pages/app/Journals';
import JournalDetailsPage from './pages/app/JournalDetails';

const publicRoutes = [
  { path: '/', component: <DemoPage /> },
  { path: '/sign-in', component: <SignIn /> },
  { path: '/forgot-password', component: <ForgotPasswordPage /> },
  { path: '/reset-password', component: <ResetPasswordPage /> },
  { path: '/create-account', component: <CreateAccount /> },
];

const appRoutes = [
  { path: 'home', component: <HomePage /> },
  { path: 'pet-directory', component: <PetDirectoryPage /> },
  { path: 'pet-directory/:petId', component: <PetProfile /> },
  { path: 'customer-book', component: <CustomerBookPage /> },
  { path: 'customer-book/:customerId', component: <CustomerProfile /> },
  { path: 'journals', component: <JournalsPage /> },
  {
    path: 'journals/:journalId',
    component: <JournalDetailsPage />,
    smallScreen: true,
  },
];

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        {publicRoutes.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={<PublicLayout>{component}</PublicLayout>}
          />
        ))}

        {/* Protected App Pages */}
        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <Routes>
                {appRoutes.map(({ path, component, smallScreen }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <AppLayout forceSmallScreenMode={!!smallScreen}>
                        {component}
                      </AppLayout>
                    }
                  />
                ))}
              </Routes>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

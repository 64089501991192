import React from 'react';
import { Box, Typography, Divider, Button, Grid2 } from '@mui/material';
import { AttachMoney, Pets } from '@mui/icons-material';
import { Customer } from '../../types/customerTypes';
import { getCountryName, getPhoneDialCode } from '../../utils/usableFunctions';

interface CustomerCardProps {
  customer: Customer;
  onEdit: () => void;
}

const CustomerCard: React.FC<CustomerCardProps> = ({ customer, onEdit }) => {
  const dialCode = getPhoneDialCode(customer.phoneCountry);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 400,
        margin: 'auto',
        padding: 3,
        border: '1px solid #E0E0E0',
        borderRadius: 12,
        boxShadow: 2,
        backgroundColor: '#FAFAFA',
      }}
    >
      {/* Customer Name */}
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: 'bold', marginBottom: 2 }}
      >
        {`${customer.name} ${customer.surname}`}
      </Typography>

      {/* Icons */}
      <Grid2 container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
        <Grid2>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 50,
              height: 50,
              backgroundColor: '#A59A80',
              borderRadius: 4,
            }}
          >
            <Pets sx={{ color: '#FFFFFF' }} />
          </Box>
        </Grid2>
        <Grid2>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 50,
              height: 50,
              backgroundColor: '#A59A80',
              borderRadius: 4,
            }}
          >
            <AttachMoney sx={{ color: '#FFFFFF' }} />
          </Box>
        </Grid2>
      </Grid2>

      {/* Details Section */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginBottom: 1,
        }}
      >
        Details
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />

      {/* Customer Details */}
      <Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Phone Number:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {dialCode ? `(${dialCode})` : ''} {customer.phoneNumber}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Contact E-mail:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {customer.email || 'N/A'}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            Social Security Number:
          </Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {customer.ssn || 'N/A'}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Address:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {`${customer.address}, ${customer.postalCode}, ${customer.city}, ${getCountryName(customer.country)}`}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Billing:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {`${customer.invoiceAddress}, ${customer.invoicePostalCode}, ${customer.invoiceCity}, ${getCountryName(customer.invoiceCountry)}`}
          </Typography>
        </Box>
      </Box>

      {/* Edit Details Button */}
      <Button
        variant="contained"
        fullWidth
        onClick={onEdit}
        sx={{
          marginTop: 3,
          backgroundColor: '#1976D2',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#115293',
          },
        }}
      >
        Edit Details
      </Button>
    </Box>
  );
};

export default CustomerCard;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, useMediaQuery } from '@mui/material';
import { supabase } from '../../../supabaseClient';
import { Customer, Pet, PetBasic } from '../../../types/customerTypes';
import CustomerCard from '../../../components/CustomerCard';
import { useAuth } from '../../../contexts/AuthContext';
import AddCustomerDrawer from '../../../components/AddCustomerDrawer';
import { AttachMoney, MailOutline, PersonOutline } from '@mui/icons-material';
import MiniNavPanel from '../../../components/MiniNav';
import NotificationIndex from '../../../components/NotificationIndex';
import BillingIndex from '../../../components/BillingIndex';
import RegisteredPetIndex from '../../../components/RegisteredPetIndex';
import AddPetFlow from '../../../components/AddPetFlow';

interface Notification {
  type: string;
  smsEnabled: boolean;
  emailEnabled: boolean;
}

const CustomerProfile: React.FC = () => {
  const { userData } = useAuth();
  const { customerId } = useParams();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [flowOpen, setFlowOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const mockNotifications = [
    { type: 'Receipts', smsEnabled: true, emailEnabled: false },
    { type: 'Journals', smsEnabled: false, emailEnabled: true },
    { type: 'Meeting Reminders', smsEnabled: true, emailEnabled: true },
  ];

  const dummyBillingDocuments = [
    { id: '1', type: 'Invoice', name: 'Invoice #1001', date: '2023-12-15' },
    { id: '2', type: 'Receipt', name: 'Receipt #2001', date: '2023-12-10' },
    { id: '3', type: 'Statement', name: 'Statement #3001', date: '2023-11-30' },
    { id: '4', type: 'Invoice', name: 'Invoice #1002', date: '2023-12-01' },
  ];

  const handleSaveNotifications = (updatedNotifications: Notification[]) => {
    // Simulate saving the data (e.g., console logging)
    console.log('Saving updated notifications:', updatedNotifications);
  };

  const handleEdit = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);
  const handleEditCustomer = async (data: Customer) => {
    try {
      if (!userData?.vet_company_id) {
        throw new Error('vet_company_id not found in user context');
      }

      if (!customer?.id) {
        throw new Error('Customer ID not found');
      }

      // Call the new RPC function
      const { error } = await supabase.rpc('edit_customer', {
        p_customer_id: customer.id,
        p_name: data.name,
        p_surname: data.surname,
        p_email: data.email,
        p_phone_country: data.phoneCountry,
        p_phone_number: data.phoneNumber,
        p_address: data.address,
        p_postal_code: data.postalCode,
        p_city: data.city,
        p_country: data.country,
        p_invoice_address: data.invoiceAddress,
        p_invoice_postal_code: data.invoicePostalCode,
        p_invoice_city: data.invoiceCity,
        p_invoice_country: data.invoiceCountry,
        p_social_security_number: data.ssn,
      });

      if (error) {
        throw error;
      }

      // Update the local state with the new data
      const updatedCustomer: Customer = {
        ...customer,
        name: data.name,
        surname: data.surname,
        email: data.email,
        ssn: data.ssn,
        phoneCountry: data.phoneCountry,
        phoneNumber: data.phoneNumber,
        address: data.address,
        postalCode: data.postalCode,
        city: data.city,
        country: data.country,
        invoiceAddress: data.invoiceAddress,
        invoicePostalCode: data.invoicePostalCode,
        invoiceCity: data.invoiceCity,
        invoiceCountry: data.invoiceCountry,
      };

      setCustomer(updatedCustomer);
    } catch (err: any) {
      console.error('Error updating customer:', err.message);
    } finally {
      setDrawerOpen(false);
    }
  };

  const handleAddNewPet = () => {
    setFlowOpen(true);
  };
  const handleCloseFlow = () => {
    setFlowOpen(false);
  };

  const handleSavePet = async (newPet: Pet) => {
    try {
      if (!userData?.vet_company_id) {
        throw new Error('Vet company ID not found in user context');
      }

      // Log the data before submission
      //   console.log('Data to be submitted:', newPet);

      // Extract weight
      const optionalWeight =
        newPet.weight && newPet.weight.length > 0
          ? newPet.weight[0].weight
          : null;

      // Extract owner IDs
      const ownerIds = newPet.owners.map((owner) => owner.id);

      // Call the `add_pet` RPC function
      const { data, error } = await supabase.rpc('add_pet', {
        p_name: newPet.name,
        p_type: newPet.type.id,
        p_breed: newPet.breed.id,
        p_chip_number: newPet.chip || null,
        p_birth_date: new Date(newPet.birthDate).toISOString().split('T')[0],
        p_sex: newPet.sex,
        p_neutered: newPet.neutered,
        p_weight: optionalWeight,
        p_insurance_company_id: newPet.insuranceCompany?.id || null,
        p_customer_ids: ownerIds, // array of UUIDs
        p_vet_company_id: userData.vet_company_id,
        p_policy_number: newPet.policyNumber || null,
        p_passport_number: newPet.passportId || null,
      });

      if (error) {
        console.error('Error adding pet:', error);
        return;
      }

      //console.log('add_pet RPC result =>', data);

      if (!data) {
        console.warn('No data returned from add_pet RPC');
        return;
      }

      // Parse the response to create the new pet
      const createdPet: PetBasic = {
        id: data.id,
        name: data.name,
        type: {
          id: data.type.id,
          type_name: data.type.type_name,
        },
        breed: {
          id: data.breed.id,
          breed_name: data.breed.breed_name,
        },
        sex: data.sex,
        age: data.age,
        owners: data.owners.map((owner: any) => ({
          id: owner.id,
          name: owner.name,
          surname: owner.surname,
        })),
      };

      // Update local state with the new pet
      setCustomer((prev) => {
        if (!prev) return prev; // or return null
        // Insert into existing array or create a new array if empty
        const updatedPets = [...(prev.pets || []), createdPet];
        // Return a new Customer object with updated pets
        return {
          ...prev,
          pets: updatedPets,
        };
      });

      //  console.log('Created pet =>', createdPet);
    } catch (err) {
      console.error('Unexpected error saving pet:', err);
    } finally {
      // Close the drawer
      setFlowOpen(false);
    }
  };

  const handleAddCoPet = () => {
    console.log('handleAddCoPet used');
  };

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (!customerId) return;

      try {
        const { data, error } = await supabase.rpc('get_customer_by_id', {
          p_customer_id: customerId,
        });

        if (error) {
          console.error('Error fetching customer details:', error);
          return;
        }

        if (data) {
          // Map the data from the RPC function to the Customer type
          const mappedCustomer: Customer = {
            id: data.id,
            name: data.name,
            surname: data.surname,
            ssn: data.social_security_number || '',
            email: data.email || null,
            phoneCountry: data.phone_country || '',
            phoneNumber: data.phone || '',
            address: data.address || '',
            postalCode: data.postal_code || '',
            city: data.city || '',
            country: data.country || 'SE', // Default value
            invoiceAddress: data.invoice_address || '',
            invoicePostalCode: data.invoice_postal_code || '',
            invoiceCity: data.invoice_city || '',
            invoiceCountry: data.invoice_country || '',
            pets: data.pets
              ? data.pets.map((pet: any) => ({
                  id: pet.id,
                  name: pet.name,
                  type: {
                    id: pet.type.id,
                    type_name: pet.type.name,
                  },
                  breed: {
                    id: pet.breed.id,
                    breed_name: pet.breed.name,
                  },
                  age: pet.age,
                  sex: pet.sex,
                }))
              : [],
          };

          setCustomer(mappedCustomer);
        }
      } catch (err) {
        console.error('Unexpected error fetching customer details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [customerId]);

  if (loading) {
    return <Typography>Loading customer data...</Typography>;
  }

  if (!customer) {
    return <Typography>No customer found.</Typography>;
  }

  return (
    <>
      <Box>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Typography color="text.secondary">Veterinary</Typography>
          <Typography color="text.secondary">Customer Book</Typography>
          <Typography color="text.primary">
            {customer.name} {customer.surname}
          </Typography>
        </Breadcrumbs>

        {/* Two-column layout */}
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          flexWrap="nowrap"
          gap={2}
        >
          <Box flex={1}>
            <CustomerCard customer={customer} onEdit={handleEdit} />
          </Box>

          {/* Two-column layout */}
          <Box flex={2}>
            <MiniNavPanel
              defaultSelected={0}
              items={[
                {
                  label: 'Overview',
                  content: (
                    <RegisteredPetIndex
                      pets={customer.pets || []}
                      onAddNewPet={handleAddNewPet}
                      onAddCoOwnPet={handleAddCoPet}
                    />
                  ),
                  icon: <PersonOutline />,
                },
                {
                  label: 'Billing',
                  content: <BillingIndex documents={dummyBillingDocuments} />,
                  icon: <AttachMoney />,
                },
                {
                  label: 'Notifications',
                  content: (
                    <NotificationIndex
                      notifications={mockNotifications}
                      onUpdate={handleSaveNotifications}
                    />
                  ),
                  icon: <MailOutline />,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      <AddCustomerDrawer
        open={drawerOpen}
        onClose={handleClose}
        mode="edit"
        onSubmit={handleEditCustomer}
        initialCustomer={customer}
      />

      <AddPetFlow
        open={flowOpen}
        mode="add"
        onClose={handleCloseFlow}
        onSave={handleSavePet}
        preselectedOwnerId={customerId}
      />
    </>
  );
};

export default CustomerProfile;

import React, { useState } from 'react';
import { Button, Box } from '@mui/material';

export interface MiniNavItem {
  label: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
}

interface MiniNavPanelProps {
  items: MiniNavItem[];
  defaultSelected?: number;
}
const MiniNavPanel: React.FC<MiniNavPanelProps> = ({
  items,
  defaultSelected = 0,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultSelected);

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Box>
      {/*Navigation Bar */}
      <Box
        display="flex"
        gap={2}
        mb={2}
        sx={{
          width: '100%',
          flexWrap: 'wrap', // Allow wrapping when the screen is small
          '& > *': {
            flex: '1 0 auto', // Ensure the buttons adjust flexibly
            minWidth: { xs: '100px', sm: '120px' }, // Responsive min width
          },
        }}
      >
        {items.map((item, idx) => {
          const isActive = idx === selectedIndex;
          return (
            <Button
              key={item.label}
              variant={isActive ? 'contained' : 'outlined'}
              onClick={() => handleSelect(idx)}
              endIcon={item.icon}
              sx={{
                borderRadius: 6, // Border radius for the buttons
                flexShrink: 0,
                textAlign: 'center',
                // Default state
                color: isActive ? '#fff' : 'text.primary',
                backgroundColor: isActive ? '#3EBAA1' : '#DAFFF8',
                borderColor: isActive ? 'transparent' : '#D9D9D9',
                // Hover state
                '&:hover': {
                  backgroundColor: isActive ? '#2DA98C' : '#C5F7EF',
                  borderColor: isActive ? 'transparent' : '#3EBAA1',
                  color: isActive ? '#fff' : '#3EBAA1',
                },
              }}
            >
              {item.label}
            </Button>
          );
        })}
      </Box>

      {/* Content for the selected item */}
      <Box>{items[selectedIndex]?.content}</Box>
    </Box>
  );
};

export default MiniNavPanel;

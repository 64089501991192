import React from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Grid2,
  IconButton,
} from '@mui/material';
import { Edit, PeopleAlt, Pets, Scale } from '@mui/icons-material';
import { Pet } from '../../types/customerTypes';
import MiniCustomerCard from '../MiniCustomerCard';

interface InformationPanelProps {
  pet: Pet;
  onEdit: () => void;
  isMinimized?: boolean;
  onRestore?: () => void;
}

const InformationPanel: React.FC<InformationPanelProps> = ({
  pet,
  onEdit,
  isMinimized,
  onRestore,
}) => {
  if (isMinimized) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* You can define whichever icons you like for the minimized state */}
        {/* Each icon calls onRestore when clicked */}
        <IconButton onClick={onRestore} size="large">
          <Pets />
        </IconButton>
        <IconButton onClick={onRestore} size="large">
          <Scale />
        </IconButton>
        <IconButton onClick={onRestore} size="large">
          <Edit />
        </IconButton>
        <IconButton onClick={onRestore} size="large">
          <PeopleAlt />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box>
      {/* Pet Name */}
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: 'bold', marginBottom: 2 }}
      >
        {pet.name}
      </Typography>

      {/* Icons and Text */}
      <Grid2 container spacing={2} sx={{ mb: 2 }}>
        {/* Pet Icon and Details */}
        <Grid2
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align items to the top
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 50,
              height: 50,
              backgroundColor: '#A59A80',
              borderRadius: 4,
              flexShrink: 0, // Prevent the icon from shrinking
            }}
          >
            <Pets sx={{ color: '#FFFFFF' }} />
          </Box>
          <Box sx={{ wordBreak: 'break-word' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {pet.breed.breed_name}
            </Typography>
            <Typography color="textSecondary">{pet.type.type_name}</Typography>
          </Box>
        </Grid2>

        {/* Weight Icon and Details */}
        <Grid2
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align items to the top
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 50,
              height: 50,
              backgroundColor: '#A59A80',
              borderRadius: 4,
              flexShrink: 0, // Prevent the icon from shrinking
            }}
          >
            <Scale sx={{ color: '#FFFFFF' }} />
          </Box>
          <Box sx={{ wordBreak: 'break-word' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {pet.weight &&
              pet.weight.length > 0 &&
              pet.weight[0].weight != null
                ? `${pet.weight[0].weight} Kg`
                : '0 Kg'}
            </Typography>
            <Typography color="textSecondary">Weight</Typography>
          </Box>
        </Grid2>
      </Grid2>

      {/* Details Section */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginBottom: 1,
        }}
      >
        Details
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />

      {/* Customer Details */}
      <Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Chip Number:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {pet.chip ?? 'N/A'}
          </Typography>
        </Box>

        {/* Row: Age + Birth Date */}
        <Box display="flex" justifyContent="space-between" gap={2} mb={1}>
          {/* Age */}
          <Box display="flex">
            <Typography sx={{ fontWeight: 'bold' }}>Age:</Typography>
            <Typography color="textSecondary" sx={{ ml: 1 }}>
              {pet.age != null ? `${pet.age} years old` : 'N/A'}
            </Typography>
          </Box>

          {/* Birth Date */}
          <Box display="flex">
            <Typography sx={{ fontWeight: 'bold' }}>Birth Date:</Typography>
            <Typography color="textSecondary" sx={{ ml: 1 }}>
              {pet.birthDate
                ? new Date(pet.birthDate).toLocaleDateString()
                : 'N/A'}
            </Typography>
          </Box>
        </Box>

        {/* Row: Sex + Neutered */}
        <Box display="flex" justifyContent="space-between" gap={2} mb={1}>
          <Box display="flex">
            <Typography sx={{ fontWeight: 'bold' }}>Sex:</Typography>
            <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
              {pet.sex === 'f' ? 'Female' : pet.sex === 'm' ? 'Male' : 'N/A'}
            </Typography>
          </Box>

          <Box display="flex">
            <Typography sx={{ fontWeight: 'bold' }}>Neutered:</Typography>
            <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
              {pet.neutered ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </Box>

        {/* Insurance Company */}

        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            Insurance Company:
          </Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {pet.insuranceCompany?.name ?? 'N/A'}
          </Typography>
        </Box>

        {/* Policy Number */}
        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Policy Number:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {pet.policyNumber ?? 'N/A'}
          </Typography>
        </Box>

        {/* Passport Id */}
        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Passport id:</Typography>
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            {pet.passportId ?? 'N/A'}
          </Typography>
        </Box>
      </Box>

      {/* Edit Details Button */}
      <Button
        variant="contained"
        fullWidth
        onClick={onEdit}
        sx={{
          marginTop: 1,
          backgroundColor: '#1976D2',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#115293',
          },
        }}
      >
        Edit Details
      </Button>

      {/* Registered Owners */}
      <Box sx={{ marginTop: 2, marginBottom: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: 1,
            }}
          >
            Registered Owners
          </Typography>

          {/* Conditionally render the icon if onRestore is provided */}
          {onRestore && <PeopleAlt sx={{ color: '#A59A80' }} />}
        </Box>

        <Divider sx={{ marginBottom: 2 }} />
        {pet.owners.length > 0 ? (
          pet.owners.map((owner) => (
            <MiniCustomerCard key={owner.id} customer={owner} />
          ))
        ) : (
          <Typography color="textSecondary" sx={{ marginLeft: 1 }}>
            No owners registered.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InformationPanel;

import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Typography, Box, Stack, Button } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import { Assignment } from '@mui/icons-material';

import JournalsTable from '../../../components/JournalsTable';
import { JournalBasic } from '../../../types/journalTypes';
import { useAuth } from '../../../contexts/AuthContext';
import { supabase } from '../../../supabaseClient';

const JournalsPage: React.FC = () => {
  const { userData } = useAuth();
  const [journals, setJournals] = useState<JournalBasic[]>([]);
  const [filteredJournals, setFilteredJournals] = useState<JournalBasic[]>([]);

  // Fetch journals for the vet company on component mount
  useEffect(() => {
    const fetchJournals = async () => {
      if (!userData?.vet_company_id) return;

      try {
        const { data, error } = await supabase.rpc(
          'get_journals_for_vet_company',
          {
            p_vet_company_id: userData.vet_company_id,
          }
        );

        if (error) {
          console.error('Error fetching journals:', error.message);
          return;
        }

        // console.log('Raw journals data', data);

        // Transform data from backend into the shape expected by the table
        const transformedJournals: JournalBasic[] = data.map(
          (journal: any) => ({
            id: journal.id,
            petId: journal.patientId,
            status: journal.status.name,
            type: journal.type,
            title: journal.title,
            date: journal.date,
            mainVet: journal.mainVet || 'Unknown Vet',
            petName: journal.patientName || 'Unknown Pet',
          })
        );

        // console.log('Transformed Journal data', transformedJournals);

        setJournals(transformedJournals);
        setFilteredJournals(transformedJournals);
      } catch (error) {
        console.error('Unexpected error fetching journals:', error);
      }
    };

    fetchJournals();
  }, [userData?.vet_company_id]);

  const handleSearch = (value: string) => {
    const lowerCasedValue = value.toLowerCase();
    const filtered = journals.filter((journal) => {
      // Match journal's title
      const journalTitleMatch = journal.title
        .toLowerCase()
        .includes(lowerCasedValue);

      // Match main vet's name
      const journalMainVetMatch = journal.mainVet
        .toLowerCase()
        .includes(lowerCasedValue);

      // Match pet's name
      const journalPetNameMatch = journal.petName
        .toLowerCase()
        .includes(lowerCasedValue);

      // Return true if any match
      return journalTitleMatch || journalMainVetMatch || journalPetNameMatch;
    });

    setFilteredJournals(filtered);
  };

  return (
    <>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.secondary">Veterinary</Typography>
        <Typography color="text.primary">Pet Directory</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        Journals
      </Typography>

      {/* Single Column Layout with Two "Rows" */}
      <Stack direction="column" spacing={2}>
        {/* Row 1: we'll use flex layout to let the SearchBar expand */}
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={2}
          sx={{ px: 1 }}
        >
          {/* Make the Box around SearchBar grow to fill leftover space */}
          <Box sx={{ flexGrow: 1 }}>
            <SearchBar
              placeholder="Search for journal"
              onSearch={handleSearch}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            endIcon={<Assignment />}
            sx={{ borderRadius: 12 }}
          >
            Add Journal
          </Button>
        </Box>

        <Box sx={{ px: 1 }}>
          <JournalsTable data={filteredJournals} />
        </Box>
      </Stack>
    </>
  );
};

export default JournalsPage;

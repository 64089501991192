import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  Box,
  IconButton,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { JournalBasic } from '../../types/journalTypes';
import { useNavigate } from 'react-router-dom';

interface JournalsTableProps {
  data: JournalBasic[];
}

const JournalsTable: React.FC<JournalsTableProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 25;
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    navigate(`/app/journals/${id}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const paginatedData = data.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2, // 16px border radius
        overflowX: isMobile ? 'visible' : 'auto', // Enable scrolling in desktop mode
      }}
    >
      {isMobile ? (
        // Mobile View: Single-column layout
        <Table>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No journals available 🐕‍🦺
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((journals) => {
                return (
                  <TableRow
                    key={journals.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    onClick={() => handleRowClick(journals.id)}
                  >
                    <TableCell>
                      <Typography
                        component="span"
                        sx={{
                          color:
                            journals.status === 'Open' ? 'orange' : 'green',
                        }}
                      >
                        <strong>Status:</strong> {journals.status}
                      </Typography>
                      <Typography>
                        <strong>Type:</strong> {journals.type}
                      </Typography>
                      <Typography>
                        <strong>Title:</strong> {journals.title}
                      </Typography>
                      <Typography>
                        <strong>Date:</strong>
                        {new Date(journals.date).toLocaleDateString()}
                      </Typography>
                      <Typography>
                        <strong>Main Vet:</strong> {journals.mainVet}
                      </Typography>
                      <Typography>
                        <strong>Pet:</strong> {journals.petName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      ) : (
        // Desktop View: Scrollable table
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
              <TableCell sx={{ width: '10%' }}>
                <strong>Status</strong>
              </TableCell>
              <TableCell sx={{ width: '10%' }}>
                <strong>Type</strong>
              </TableCell>
              <TableCell sx={{ width: '25%' }}>
                <strong>Title</strong>
              </TableCell>
              <TableCell sx={{ width: '15%' }}>
                <strong>Date</strong>
              </TableCell>
              <TableCell sx={{ width: '25%' }}>
                <strong>Main Vet</strong>
              </TableCell>
              <TableCell sx={{ width: '15%' }}>
                <strong>Pet</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No customers available 🐕‍🦺
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((journals) => {
                return (
                  <TableRow
                    key={journals.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    onClick={() => handleRowClick(journals.id)}
                  >
                    <TableCell>
                      <Typography
                        sx={{
                          color:
                            journals.status === 'Open' ? 'orange' : 'green',
                        }}
                      >
                        {journals.status}
                      </Typography>
                    </TableCell>
                    <TableCell>{journals.type}</TableCell>
                    <TableCell>{journals.title}</TableCell>
                    <TableCell>
                      {new Date(journals.date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{journals.mainVet}</TableCell>
                    <TableCell>{journals.petName}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <IconButton
            onClick={handlePrevPage}
            disabled={currentPage === 0}
            aria-label="Previous Page"
          >
            <ChevronLeft />
          </IconButton>
          <Typography>
            Page {currentPage + 1} of {totalPages}
          </Typography>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
            aria-label="Next Page"
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
    </TableContainer>
  );
};

export default JournalsTable;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  useMediaQuery,
} from '@mui/material';

interface Notification {
  type: string;
  smsEnabled: boolean;
  emailEnabled: boolean;
}

interface NotificationIndexProps {
  notifications: Notification[]; // Initial data
  onUpdate: (updatedNotifications: Notification[]) => void; // Save handler
}

const NotificationIndex: React.FC<NotificationIndexProps> = ({
  notifications,
  onUpdate,
}) => {
  const [localNotifications, setLocalNotifications] =
    useState<Notification[]>(notifications);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const handleCheckboxChange = (
    index: number,
    field: 'smsEnabled' | 'emailEnabled',
    value: boolean
  ) => {
    const updatedNotifications = [...localNotifications];
    updatedNotifications[index][field] = value;
    setLocalNotifications(updatedNotifications);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      onUpdate(updatedNotifications);
    }, 1000);
    setDebounceTimeout(timeout);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  return (
    <Box
      sx={{
        padding: 3,
        border: '1px solid #E0E0E0',
        borderRadius: 12,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      {/* Heading */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Notifications
      </Typography>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          overflowX: isSmallScreen ? 'auto' : 'hidden',
        }}
      >
        <Table>
          {!isSmallScreen && (
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>SMS</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Email</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {localNotifications.map((notification, index) => (
              <TableRow key={notification.type}>
                {isSmallScreen ? (
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>
                        <strong>Type: </strong> {notification.type}
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Typography>
                          <strong>SMS: </strong>
                        </Typography>
                        <Checkbox
                          checked={notification.smsEnabled}
                          onChange={(e) =>
                            handleCheckboxChange(
                              index,
                              'smsEnabled',
                              e.target.checked
                            )
                          }
                        />
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Typography>
                          <strong>Email: </strong>
                        </Typography>
                        <Checkbox
                          checked={notification.emailEnabled}
                          onChange={(e) =>
                            handleCheckboxChange(
                              index,
                              'emailEnabled',
                              e.target.checked
                            )
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                ) : (
                  <>
                    <TableCell>{notification.type}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={notification.smsEnabled}
                        onChange={(e) =>
                          handleCheckboxChange(
                            index,
                            'smsEnabled',
                            e.target.checked
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={notification.emailEnabled}
                        onChange={(e) =>
                          handleCheckboxChange(
                            index,
                            'emailEnabled',
                            e.target.checked
                          )
                        }
                      />
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NotificationIndex;

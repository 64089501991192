import React, { useEffect, useMemo } from 'react';
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Collapse,
  Autocomplete,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Customer } from '../../types/customerTypes';
import { useAuth } from '../../contexts/AuthContext';
import i18nIsoCountries from '../../services/i18Countries';
import { phoneCountryList } from '../../services/phoneCountries';

interface AddCustomerDrawerProps {
  open: boolean;
  onClose: () => void;
  mode?: 'add' | 'edit';
  initialCustomer?: Customer;
  onSubmit?: (data: Customer) => void;
}

/**
 * We'll store form data in a type that includes everything from Customer
 * except `pets`, plus a `useSameAddress` boolean (not in the DB).
 * Email is optional (string | null), per your original specification.
 */
interface CustomerFormData extends Omit<Customer, 'pets'> {
  useSameAddress: boolean;
}

/**
 * Yup validation schema.
 * - All invoice fields are always required.
 * - Phone number must be numeric.
 * - Email can be null or empty but if it is provided, must be valid.
 */
const schema = yup.object({
  id: yup.string(),
  name: yup.string().required('Name is required'),
  surname: yup.string().required('Surname is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .trim()
    .nullable()
    .test('is-valid-domain', 'Invalid email domain', (value) => {
      if (!value) return true; // Skip if empty
      const domainRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return domainRegex.test(value);
    }), // 'email' can be null in the DB, or empty string in the form
  ssn: yup.string().nullable().defined(),
  phoneCountry: yup.string().required('Country code is required'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d+$/, 'Phone number must be numeric'),
  address: yup.string().required('Address is required'),
  postalCode: yup.string().required('Postal code is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),

  invoiceAddress: yup.string().required('Invoice address is required'),
  invoicePostalCode: yup.string().required('Invoice postal code is required'),
  invoiceCity: yup.string().required('Invoice city is required'),
  invoiceCountry: yup.string().required('Invoice country is required'),

  useSameAddress: yup.boolean().required(),
});

const AddCustomerDrawer: React.FC<AddCustomerDrawerProps> = ({
  open,
  onClose,
  mode = 'add',
  initialCustomer,
  onSubmit: parentOnSubmit,
}) => {
  const { vetCompanyData } = useAuth();

  const countryList = useMemo(() => {
    // Returns an object { "US": "United States", "SE": "Sweden", ... }
    const nameObj = i18nIsoCountries.getNames('en', { select: 'official' });
    // Convert that into an array of { iso: string; name: string }
    return Object.entries(nameObj).map(([iso, name]) => ({
      iso,
      name,
    }));
  }, []);

  const title = mode === 'edit' ? 'Edit Customer' : 'Add New Customer';

  /**
   * Decide the default form values. If editing, fill them from initialCustomer;
   * otherwise, use blank fields. Also see if addresses match to set `useSameAddress`.
   */
  const defaultValues: CustomerFormData = useMemo(() => {
    if (mode === 'edit' && initialCustomer) {
      // Check if personal & invoice addresses match
      const addressesMatch =
        initialCustomer.address === initialCustomer.invoiceAddress &&
        initialCustomer.postalCode === initialCustomer.invoicePostalCode &&
        initialCustomer.city === initialCustomer.invoiceCity &&
        initialCustomer.country === initialCustomer.invoiceCountry;

      return {
        id: initialCustomer.id,
        name: initialCustomer.name,
        surname: initialCustomer.surname,
        ssn: initialCustomer.ssn,
        email: initialCustomer.email || '',
        phoneCountry: initialCustomer.phoneCountry || 'SE',
        phoneNumber: initialCustomer.phoneNumber,
        address: initialCustomer.address,
        postalCode: initialCustomer.postalCode,
        city: initialCustomer.city,
        country: initialCustomer.country,
        invoiceAddress: initialCustomer.invoiceAddress,
        invoicePostalCode: initialCustomer.invoicePostalCode,
        invoiceCity: initialCustomer.invoiceCity,
        invoiceCountry: initialCustomer.invoiceCountry,
        useSameAddress: addressesMatch,
      };
    }

    // If adding a customer, default to empty fields + useSameAddress = true
    return {
      id: undefined,
      name: '',
      surname: '',
      email: null,
      ssn: '',
      phoneCountry: vetCompanyData?.country || 'SE',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      country: vetCompanyData?.country || 'SE',
      invoiceAddress: '',
      invoicePostalCode: '',
      invoiceCity: '',
      invoiceCountry: vetCompanyData?.country || 'SE',
      useSameAddress: true,
    };
  }, [mode, initialCustomer, vetCompanyData?.country]);

  // Set up React Hook Form
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CustomerFormData>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  // Watch fields for changes
  const useSameAddress = watch('useSameAddress');
  const mainAddress = watch('address');
  const mainPostalCode = watch('postalCode');
  const mainCity = watch('city');
  const mainCountry = watch('country');

  /**
   * If user toggles `useSameAddress` ON, copy main address to invoice fields.
   * If user toggles OFF, reset invoice fields to empty (like original code).
   */
  useEffect(() => {
    if (useSameAddress) {
      setValue('invoiceAddress', mainAddress);
      setValue('invoicePostalCode', mainPostalCode);
      setValue('invoiceCity', mainCity);
      setValue('invoiceCountry', mainCountry);
    } else {
      setValue('invoiceAddress', '');
      setValue('invoicePostalCode', '');
      setValue('invoiceCity', '');
      setValue('invoiceCountry', 'SE');
    }
  }, [
    useSameAddress,
    mainAddress,
    mainPostalCode,
    mainCity,
    mainCountry,
    setValue,
  ]);

  /**
   * Whenever the drawer is opened (or defaultValues changes), reset the form.
   */
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [defaultValues, open, reset]);

  /**
   * On form submit, build the final `Customer` payload.
   */
  const onSubmit: SubmitHandler<CustomerFormData> = (formData) => {
    const payload: Customer = {
      id: formData.id,
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      ssn: formData.ssn,
      phoneCountry: formData.phoneCountry,
      phoneNumber: formData.phoneNumber,
      address: formData.address,
      postalCode: formData.postalCode,
      city: formData.city,
      country: formData.country,
      invoiceAddress: formData.invoiceAddress,
      invoicePostalCode: formData.invoicePostalCode,
      invoiceCity: formData.invoiceCity,
      invoiceCountry: formData.invoiceCountry,
    };

    if (parentOnSubmit) {
      parentOnSubmit(payload);
    }
    handleClose();
  };

  /**
   * Close drawer logic: reset form & call onClose.
   */
  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: 480 },
          p: 3,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5">{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          {/* -- Name + Surname -- */}
          <Box display="flex" gap={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Controller
              name="surname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Surname"
                  fullWidth
                  error={!!errors.surname}
                  helperText={errors.surname?.message}
                />
              )}
            />
          </Box>

          {/* -- Email -- */}
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''} // Ensure value is always a string
                label="Email"
                type="email"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />

          {/* -- SSN -- */}
          <Controller
            name="ssn"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Social Security Number" fullWidth />
            )}
          />

          {/* -- Phone Country + Phone Number -- */}
          <Box display="flex" gap={2}>
            <Controller
              name="phoneCountry"
              control={control}
              render={({ field, fieldState }) => {
                // Convert the current ISO code (field.value) to the object { iso, dial_code, name }
                const selectedOption =
                  phoneCountryList.find((opt) => opt.iso === field.value) ||
                  null;

                return (
                  <Autocomplete
                    options={phoneCountryList}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.dial_code})`
                    }
                    value={selectedOption}
                    onChange={(_, newVal) => {
                      // If user picks a new item => store only the iso code, e.g. "SE"
                      field.onChange(newVal?.iso || '');
                    }}
                    isOptionEqualToValue={(option, val) =>
                      option.iso === val?.iso
                    }
                    // If you want it disabled under any conditions, do `disabled={someBool}`
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country Code"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                    sx={{ flex: 1 }}
                  />
                );
              }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  sx={{ flex: 1 }}
                />
              )}
            />
          </Box>

          {/* -- Address Section -- */}
          <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
            Address
          </Typography>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address"
                fullWidth
                error={!!errors.address}
                helperText={errors.address?.message}
              />
            )}
          />
          <Box display="flex" gap={2}>
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Postal Code"
                  fullWidth
                  error={!!errors.postalCode}
                  helperText={errors.postalCode?.message}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="City"
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              )}
            />
          </Box>
          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              // Convert the ISO code (field.value) to an object { iso, name }
              const selected =
                countryList.find((c) => c.iso === field.value) || null;

              return (
                <Autocomplete
                  options={countryList}
                  // We'll display country name as the label
                  getOptionLabel={(option) => option.name}
                  value={selected}
                  onChange={(_, newVal) => {
                    // newVal might be null if user clears
                    field.onChange(newVal?.iso || '');
                  }}
                  isOptionEqualToValue={(option, val) => option.iso === val.iso}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      variant="outlined"
                      error={!!errors.country}
                      helperText={errors.country?.message}
                    />
                  )}
                />
              );
            }}
          />

          {/* -- Invoice Address Section -- */}
          <FormControlLabel
            label="Use same address for invoicing"
            control={
              <Controller
                name="useSameAddress"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
          />

          <Collapse in={!useSameAddress}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2, fontWeight: 'bold' }}
              >
                Invoice Address
              </Typography>
              <Controller
                name="invoiceAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Address"
                    fullWidth
                    disabled={useSameAddress}
                    error={!!errors.invoiceAddress}
                    helperText={errors.invoiceAddress?.message}
                  />
                )}
              />
              <Box display="flex" gap={2}>
                <Controller
                  name="invoicePostalCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Postal Code"
                      fullWidth
                      disabled={useSameAddress}
                      error={!!errors.invoicePostalCode}
                      helperText={errors.invoicePostalCode?.message}
                    />
                  )}
                />
                <Controller
                  name="invoiceCity"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="City"
                      fullWidth
                      disabled={useSameAddress}
                      error={!!errors.invoiceCity}
                      helperText={errors.invoiceCity?.message}
                    />
                  )}
                />
              </Box>
              <Controller
                name="invoiceCountry"
                control={control}
                render={({ field }) => {
                  // Convert the ISO code to { iso, name }
                  const selected =
                    countryList.find((c) => c.iso === field.value) || null;

                  return (
                    <Autocomplete
                      options={countryList}
                      getOptionLabel={(option) => option.name}
                      value={selected}
                      onChange={(_, newVal) => {
                        field.onChange(newVal?.iso || '');
                      }}
                      isOptionEqualToValue={(option, val) =>
                        option.iso === val.iso
                      }
                      // <-- here's the key
                      disabled={useSameAddress}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Invoice Country"
                          error={!!errors.invoiceCountry}
                          helperText={errors.invoiceCountry?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            </Box>
          </Collapse>

          {/* -- Action Buttons -- */}
          <Box display="flex" justifyContent="flex-end" gap={2} mb={2} mt={2}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {mode === 'edit' ? 'Save Changes' : 'Save Customer'}
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};

export default AddCustomerDrawer;

import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DOMPurify from 'dompurify';
import { JournalTextEntry } from '../../types/journalTypes';

interface JournalTextEntryContainerProps {
  entry: JournalTextEntry;
  onEdit?: () => void;
}

const JournalTextEntryContainer: React.FC<JournalTextEntryContainerProps> = ({
  entry,
  onEdit,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // If you want to sanitize the HTML before rendering, you can use e.g. `dompurify`
  // so you do: dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(entry.rendered_text) }}
  const renderedHtml = entry.rendered_text || '<p>No content available</p>';

  // Convert or format date if needed
  const dateString = entry.created_at
    ? new Date(entry.created_at).toLocaleString()
    : 'Unknown date';

  return (
    <Box
      sx={{
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Main text content */}
      <Box
        sx={{
          paddingX: 1,
          borderRadius: 2,
          backgroundColor: isHovered ? 'white' : '#FAFAFA',
          border: isHovered ? '1px solid #d0d0d0' : '1px solid transparent',
          boxShadow: isHovered ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
          '& p': { margin: 0 }, // Reset margins for <p> tags
          '& h1, h2, h3, h4, h5, h6': { margin: 0 }, // Reset margins for <h> tags
        }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(renderedHtml) }}
      />

      {/* 
        Extra row. We always reserve some vertical space 
        so the box doesn't "jump" on hover. 
        Let's do minHeight=24 or so. 
      */}
      <Box
        sx={{
          minHeight: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isHovered ? (
          <>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
                display: 'inline', // remove block-level spacing
                m: 0, // ensure no margin
                lineHeight: '1.2', // or a consistent line-height
              }}
            >
              Last edited by: {dateString}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
                display: 'inline', // remove block-level spacing
                m: 0, // ensure no margin
                lineHeight: '1.2', // or a consistent line-height
              }}
            >
              Last edited by: {entry.createdByName}
            </Typography>
            {onEdit && (
              <IconButton
                onClick={onEdit}
                size="medium"
                sx={{
                  ml: 1,
                  p: 0, // remove extra padding
                  lineHeight: 1, // keep it tight
                }}
                aria-label="Edit entry"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </>
        ) : (
          // If not hovering, leave this row empty (but with minHeight)
          <></>
        )}
      </Box>
    </Box>
  );
};

export default JournalTextEntryContainer;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Stack,
  Link as MuiLink,
} from '@mui/material';
import MuiCard from '@mui/material/Card';
import { supabase } from '../../../supabaseClient';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
}));

const ForgotPasswordContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMsg('');
    setSuccessMsg('');

    if (!email) {
      setErrorMsg('Please enter your email address.');
      return;
    }

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      //redirectTo: `${window.location.origin}/reset-password`,
      redirectTo: 'http://localhost:3000/reset-password',
    });

    if (error) {
      setErrorMsg(error.message);
      return;
    }

    setSuccessMsg('A password reset link has been sent to your email address.');
  };

  return (
    <>
      <CssBaseline />
      <ForgotPasswordContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Forgot Password
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <TextField
              type="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {errorMsg && (
              <Typography color="error" variant="body2">
                {errorMsg}
              </Typography>
            )}
            {successMsg && (
              <Typography color="primary" variant="body2">
                {successMsg}
              </Typography>
            )}

            <Button type="submit" fullWidth variant="contained">
              Send Reset Link
            </Button>

            <Typography sx={{ textAlign: 'center' }}>
              <MuiLink href="/sign-in" variant="body2">
                Back to Sign In
              </MuiLink>
            </Typography>
          </Box>
        </Card>
      </ForgotPasswordContainer>
    </>
  );
};

export default ForgotPasswordPage;

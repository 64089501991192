import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import WeightChart from '../WeightChart';
import QuickPetActions from '../QuickPetActions';
import JournalIndex from '../JournalIndex';
import { Weight } from '../../types/customerTypes';
import { JournalBasic } from '../../types/journalTypes';

// Props shape for PetOverviewIndex
interface PetOverviewIndexProps {
  weights: Weight[]; // Data for WeightChart
  petId?: string; // Pet ID for QuickPetActions
  journals: JournalBasic[]; // Data for JournalIndex
}

const PetOverviewIndex: React.FC<PetOverviewIndexProps> = ({
  weights,
  petId,
  journals,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  // We define a 2-row layout for bigger screens:
  // Row 1 => WeightChart (flex-grow) & QuickPetActions (fixed width)
  // Row 2 => JournalIndex
  // On small screens, each is its own row.

  return (
    <Box
      sx={{
        // No background color or border, just spacing
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* Row 1 on large screen => WeightChart & QuickPetActions side by side */}
      {/* On small screen => stacked */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          gap: 2,
          width: '100%',
        }}
      >
        {/* WeightChart - flexGrow so it expands horizontally */}
        <Box sx={{ flexGrow: 1 }}>
          <WeightChart data={weights} />
        </Box>

        {/* QuickPetActions - fixed width or auto width */}
        <Box
          sx={{
            width: isSmallScreen ? '100%' : 'auto',
            flexShrink: 0,
          }}
        >
          <QuickPetActions pet_id={petId} />
        </Box>
      </Box>

      {/* Row 2 => JournalIndex (occupies full width in both small & large) */}
      <Box sx={{ width: '100%' }}>
        <JournalIndex entries={journals} />
      </Box>
    </Box>
  );
};

export default PetOverviewIndex;

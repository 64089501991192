import { createTheme } from '@mui/material/styles';

const GlobalTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase globally
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 16, // Add border radius globally to outlined inputs
        },
      },
    },
  },
});

export default GlobalTheme;

// services/phoneCountries.ts
import { allCountries } from 'country-telephone-data';
import i18nIsoCountries from './i18Countries';

// Transform the data from `country-telephone-data`
// which is { name: "Sweden", iso2: "se", dialCode: "46", ... }
// into an array: { iso: "SE", dial_code: "+46", name: "Sverige" }
export const phoneCountryList = allCountries.map((item) => {
  const isoCode = item.iso2.toUpperCase(); // "SE", "US", ...
  const dialCode = `+${item.dialCode}`; // "+46"

  // Use i18nIsoCountries to get the official Swedish name
  const swedishName = i18nIsoCountries.getName(isoCode, 'sv', {
    select: 'official',
  });

  return {
    iso: isoCode,
    dial_code: dialCode,
    name: swedishName || item.name, // Fallback to the English name if missing
  };
});

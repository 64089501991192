import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface ProtectedRouteProps {
  children: ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { sessionData, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!sessionData) {
    return <Navigate to="/sign-in" replace />;
  }

  // Wrap children in a fragment (or return children directly)
  return <>{children}</>;
}

export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, useMediaQuery } from '@mui/material';
import { supabase } from '../../../supabaseClient';
import {
  AttachFileOutlined,
  AttachMoney,
  ContentPaste,
  Pets,
} from '@mui/icons-material';
import MiniNavPanel from '../../../components/MiniNav';
import PetCard from '../../../components/PetCard';
import { Pet, Weight } from '../../../types/customerTypes';
import AddPetFlow from '../../../components/AddPetFlow';
import BillingIndex from '../../../components/BillingIndex';
import JournalIndex from '../../../components/JournalIndex';
import WeightChart from '../../../components/WeightChart';
import PetOverviewIndex from '../../../components/PetOverviewIndex';
import { JournalBasic } from '../../../types/journalTypes';

const dummyBillingDocuments = [
  { id: '1', type: 'Invoice', name: 'Invoice #1001', date: '2023-12-15' },
  { id: '2', type: 'Receipt', name: 'Receipt #2001', date: '2023-12-10' },
  { id: '3', type: 'Statement', name: 'Statement #3001', date: '2023-11-30' },
  { id: '4', type: 'Invoice', name: 'Invoice #1002', date: '2023-12-01' },
];

const mockWeights: Weight[] = [
  { id: '1', weight: 4.5, date: new Date('2025-01-02') },
  { id: '2', weight: 5.1, date: new Date('2024-02-10') },
  { id: '3', weight: 5.7, date: new Date('2023-03-22') },
  { id: '4', weight: 6.2, date: new Date('2024-04-05') },
  { id: '5', weight: 6.8, date: new Date('2023-06-10') },
];

const mockJournals: JournalBasic[] = [
  {
    id: '1',
    status: 'Open',
    type: 'Exam',
    title: 'Skin Allergy Check',
    date: '2024-01-10',
    mainVet: 'Dr. Emily Brown',
    petName: 'Richi',
  },
  {
    id: '2',
    status: 'Closed',
    type: 'Surgery',
    title: 'Knee Surgery',
    date: '2024-02-15',
    mainVet: 'Dr. John White',
    petName: 'Richi',
  },
  {
    id: '3',
    status: 'Open',
    type: 'Vaccination',
    title: 'Rabies Vaccine',
    date: '2024-03-05',
    mainVet: 'Dr. Sarah Green',
    petName: 'Richi',
  },
];

/*const mockAppointments: AppointmentEntry[] = [
  {
    id: '1',
    status: 'Planned',
    type: 'Follow-up',
    name: 'Skin Allergy Check',
    date: '2024-01-10',
    veterinary: 'Dr. Emily Brown',
  },
  {
    id: '2',
    status: 'Ongoing',
    type: 'Issue',
    name: 'Knee Surgery',
    date: '2024-02-15',
    veterinary: 'Dr. John White',
  },
  {
    id: '3',
    status: 'Past',
    type: 'Dental',
    name: 'Molar removal',
    date: '2024-03-05',
    veterinary: 'Dr. Sarah Green',
  },
];*/

const PetProfile: React.FC = () => {
  const { petId } = useParams(); // Update param name to petId
  const [pet, setPet] = useState<Pet | null>(null);
  const [loading, setLoading] = useState(true);
  const [flowOpen, setFlowOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchPetDetails = async () => {
      if (!petId) return;

      try {
        const { data, error } = await supabase.rpc('get_pet_by_id', {
          p_pet_id: petId,
        });

        if (error) {
          console.error('Error fetching pet details:', error);
          setLoading(false);
          return;
        }

        if (!data) {
          console.warn('No pet data returned.');
          setLoading(false);
          return;
        }

        //console.log('Raw Pet Data:', data);

        // Map the data to the `Pet` type
        const mappedPet: Pet = {
          id: data.id,
          name: data.name,
          insuranceCompany: data.insuranceCompany?.id
            ? {
                id: data.insuranceCompany.id,
                name: data.insuranceCompany.name,
              }
            : null,
          policyNumber: data.policyNumber || null,
          passportId: data.passportId || null,
          type: {
            id: data.type.id,
            type_name: data.type.type_name,
          },
          breed: {
            id: data.breed.id,
            breed_name: data.breed.breed_name,
          },
          chip: data.chip || null,
          birthDate: data.birthDate,
          age: data.age,
          sex: data.sex,
          neutered: data.neutered,
          weight: data.weight || [],
          owners: data.owners?.map((owner: any) => ({
            id: owner.id,
            name: owner.name,
            surname: owner.surname,
            email: owner.email || null,
            phoneNumber: owner.phoneNumber || null,
          })),
        };

        //console.log('Mapped Pet Data:', mappedPet);
        setPet(mappedPet);
      } catch (err) {
        console.error('Unexpected error fetching pet details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPetDetails();
  }, [petId]);

  if (loading) {
    return <Typography>Loading pet data...</Typography>;
  }

  if (!pet) {
    return <Typography>No pet found.</Typography>;
  }

  const handleOpenFlow = () => {
    setFlowOpen(true);
  };

  const handleCloseFlow = () => {
    setFlowOpen(false);
  };

  const handleEditPet = async (updatedPet: Pet) => {
    try {
      if (!updatedPet.id) {
        console.error('Cannot edit pet without an ID.');
        return;
      }

      // (Note: owners/weight are not in edit_pet's parameters.)
      const { data, error } = await supabase.rpc('edit_pet', {
        p_pet_id: updatedPet.id,
        p_name: updatedPet.name,
        p_animal_type_id: updatedPet.type.id,
        p_breed_id: updatedPet.breed.id,
        p_chip_number: updatedPet.chip || '',
        p_birth_date: updatedPet.birthDate,
        p_sex: updatedPet.sex,
        p_neutered: updatedPet.neutered,
        p_insurance_company_id: updatedPet.insuranceCompany?.id || null,
        p_policy_number: updatedPet.policyNumber || '',
        p_passport_number: updatedPet.passportId || '',
      });

      if (error) {
        console.error('Error editing pet:', error);
        return;
      }

      if (!data) {
        console.warn('No data returned from edit_pet function.');
        return;
      }

      // data is the new JSON object from the DB
      // Parse it to a Pet
      const dbPet: any = data;
      const editedPet: Pet = {
        id: dbPet.id,
        name: dbPet.name,
        insuranceCompany: dbPet.insuranceCompany?.id
          ? {
              id: dbPet.insuranceCompany.id,
              name: dbPet.insuranceCompany.name,
            }
          : null,
        policyNumber: dbPet.policyNumber || null,
        passportId: dbPet.passportId || null,
        type: {
          id: dbPet.type.id,
          type_name: dbPet.type.type_name,
        },
        breed: {
          id: dbPet.breed.id,
          breed_name: dbPet.breed.breed_name,
        },
        chip: dbPet.chip || null,
        birthDate: dbPet.birthDate,
        age: dbPet.age,
        sex: dbPet.sex,
        neutered: dbPet.neutered,
        weight: dbPet.weight || [], // or empty if not returned
        owners: (dbPet.owners || []).map((owner: any) => ({
          id: owner.id,
          name: owner.name,
          surname: owner.surname,
          email: owner.email || null,
          phoneNumber: owner.phoneNumber || null,
        })),
      };

      // Update local state
      setPet(editedPet);
      console.log('Successfully edited pet:', editedPet);
    } catch (err) {
      console.error('Unexpected error editing pet:', err);
    } finally {
      // Close the flow
      handleCloseFlow();
    }
  };

  return (
    <>
      <Box>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Typography color="text.secondary">Veterinary</Typography>
          <Typography color="text.secondary">Pet Directory</Typography>
          <Typography color="text.primary">{pet.name}</Typography>
        </Breadcrumbs>

        {/* Two-column layout */}
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          flexWrap="nowrap"
          gap={2}
        >
          <Box flex={1}>
            <PetCard pet={pet} onEdit={handleOpenFlow} />
          </Box>

          {/* Two-column layout */}
          <Box flex={2}>
            <MiniNavPanel
              defaultSelected={0}
              items={[
                {
                  label: 'Overview',
                  content: (
                    <PetOverviewIndex
                      weights={mockWeights}
                      petId={petId}
                      journals={mockJournals}
                    />
                  ),
                  icon: <Pets />,
                },
                {
                  label: 'Journals',
                  content: <JournalIndex entries={mockJournals} />,
                  icon: <ContentPaste />,
                },
                {
                  label: 'Clinical History',
                  content: <WeightChart data={mockWeights} />,
                  icon: <AttachFileOutlined />,
                },
                {
                  label: 'Billing',
                  content: <BillingIndex documents={dummyBillingDocuments} />,
                  icon: <AttachMoney />,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      {/* The multi-step flow (Add Mode) */}
      <AddPetFlow
        open={flowOpen}
        mode="edit"
        onClose={handleCloseFlow}
        onSave={handleEditPet}
        initialPet={pet}
      />
    </>
  );
};

export default PetProfile;

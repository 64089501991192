import React, { useState } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PetsIcon from '@mui/icons-material/Pets';
import BookIcon from '@mui/icons-material/Book';
import { NavLink } from 'react-router-dom';
import { Assignment } from '@mui/icons-material';

interface AppLayoutProps {
  children: React.ReactNode;
  forceSmallScreenMode?: boolean;
}

const drawerWidth = 240;
const appBackgroundColor = '#F8F1E1';

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  forceSmallScreenMode = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  // If either the actual screen is small, or we're forcing small mode,
  // we treat it like a small screen layout:
  const renderAsSmallScreen = isSmallScreen || forceSmallScreenMode;

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Drawer Content
  const drawerContent = (
    <div>
      <Toolbar />
      <List>
        <ListItemButton
          component={NavLink}
          to="/app/home"
          sx={{
            '&.active': { backgroundColor: theme.palette.action.selected },
          }}
          // auto-close if in small screen
          onClick={() => renderAsSmallScreen && handleDrawerToggle()}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>

        <ListItemButton
          component={NavLink}
          to="/app/customer-book"
          sx={{
            '&.active': { backgroundColor: theme.palette.action.selected },
          }}
          onClick={() => renderAsSmallScreen && handleDrawerToggle()}
        >
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Customer Book" />
        </ListItemButton>

        <ListItemButton
          component={NavLink}
          to="/app/pet-directory"
          sx={{
            '&.active': { backgroundColor: theme.palette.action.selected },
          }}
          onClick={() => renderAsSmallScreen && handleDrawerToggle()}
        >
          <ListItemIcon>
            <PetsIcon />
          </ListItemIcon>
          <ListItemText primary="Pet Directory" />
        </ListItemButton>

        <ListItemButton
          component={NavLink}
          to="/app/journals"
          sx={{
            '&.active': { backgroundColor: theme.palette.action.selected },
          }}
          onClick={() => renderAsSmallScreen && handleDrawerToggle()}
        >
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Journals" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        overflowX: 'auto',
        backgroundColor: appBackgroundColor,
      }}
    >
      <CssBaseline />

      {/* If we're "small screen", show the top AppBar with a menu button */}
      {renderAsSmallScreen && (
        <AppBar
          position="fixed"
          sx={{
            width: '100%',
            // No margin-left for small screen
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Veterinary Journal
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Navigation */}
      <Box
        component="nav"
        sx={{
          flexShrink: 0,
          width: renderAsSmallScreen ? 'auto' : drawerWidth,
        }}
        aria-label="menu"
      >
        {/* 1) Temporary Drawer if "small screen" */}
        <Drawer
          variant="temporary"
          open={mobileOpen && renderAsSmallScreen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // better open performance on mobile
          }}
          sx={{
            display: renderAsSmallScreen ? 'block' : 'none',
            '& .MuiDrawer-paper': { width: drawerWidth },
          }}
        >
          {drawerContent}
        </Drawer>

        {/* 2) Permanent Drawer if "large screen" */}
        <Drawer
          variant="permanent"
          sx={{
            display: renderAsSmallScreen ? 'none' : 'block',
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // If small screen, we don't offset the content by drawerWidth
          width: !renderAsSmallScreen
            ? `calc(100% - ${drawerWidth}px)`
            : '100%',
        }}
      >
        {renderAsSmallScreen && <Toolbar />}
        {children}
      </Box>
    </Box>
  );
};

export default AppLayout;

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { PetBasic } from '../../types/customerTypes';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface PetsTableProps {
  data: PetBasic[];
}

const PetsTable: React.FC<PetsTableProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 25;
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    navigate(`/app/pet-directory/${id}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const paginatedData = data.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2,
        overflowX: isMobile ? 'visible' : 'auto',
      }}
    >
      {isMobile ? (
        <Table>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell align="center">
                  <Typography>No Journals available 🐕‍🦺</Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((pet) => {
                const owners = pet.owners
                  ? pet.owners
                      .slice(0, 2) // Take only the first two owners
                      .map((o) => `${o.name} ${o.surname}`)
                      .join(', ') + (pet.owners.length > 2 ? ', ...' : '') // Add "..." if more than two owners
                  : 'No owner';

                return (
                  <TableRow
                    key={pet.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    onClick={() => handleRowClick(pet.id)}
                  >
                    <TableCell>
                      <Box display="flex" flexDirection="column">
                        <Typography>
                          <strong>Name:</strong> {pet.name}
                        </Typography>
                        <Typography>
                          <strong>Type:</strong> {pet.type.type_name}
                        </Typography>
                        <Typography>
                          <strong>Breed:</strong> {pet.breed.breed_name}
                        </Typography>
                        <Typography>
                          <strong>Sex:</strong>{' '}
                          {pet.sex.toLowerCase() === 'f' ? 'Female' : 'Male'}
                        </Typography>
                        <Typography>
                          <strong>Age:</strong> {pet.age}
                        </Typography>
                        <Typography>
                          <strong>Owner&apos;s Name:</strong> {owners}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      ) : (
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <strong>Breed</strong>
              </TableCell>
              <TableCell>
                <strong>Sex</strong>
              </TableCell>
              <TableCell>
                <strong>Age</strong>
              </TableCell>
              <TableCell>
                <strong>Owner&apos;s Name</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography>No pets available 🐕‍🦺</Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((pet) => {
                const owners = pet.owners
                  ? pet.owners
                      .slice(0, 2) // Take only the first two owners
                      .map((o) => `${o.name} ${o.surname}`)
                      .join(', ') + (pet.owners.length > 2 ? ', ...' : '') // Add "..." if more than two owners
                  : 'No owner';

                return (
                  <TableRow
                    key={pet.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    onClick={() => handleRowClick(pet.id)}
                  >
                    <TableCell>{pet.name}</TableCell>
                    <TableCell>{pet.type.type_name}</TableCell>
                    <TableCell>{pet.breed.breed_name}</TableCell>
                    <TableCell>
                      {pet.sex.toLowerCase() === 'f' ? 'Female' : 'Male'}
                    </TableCell>
                    <TableCell>{pet.age}</TableCell>
                    <TableCell>{owners}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <IconButton
            onClick={handlePrevPage}
            disabled={currentPage === 0}
            aria-label="Previous Page"
          >
            <ChevronLeft />
          </IconButton>
          <Typography>
            Page {currentPage + 1} of {totalPages}
          </Typography>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
            aria-label="Next Page"
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
    </TableContainer>
  );
};

export default PetsTable;

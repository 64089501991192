import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Button,
} from '@mui/material';
import { PetBasic } from '../../types/customerTypes';
import { useNavigate } from 'react-router-dom';

interface RegisteredPetIndexProps {
  pets: PetBasic[];
  onAddNewPet: () => void; // Handler for "Add new Pet"
  onAddCoOwnPet: () => void; // Handler for "Add co-own Pet"
}

const RegisteredPetIndex: React.FC<RegisteredPetIndexProps> = ({
  pets,
  onAddNewPet,
  onAddCoOwnPet,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const noPets = !pets || pets.length === 0;
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    navigate(`/app/pet-directory/${id}`);
  };

  return (
    <Box
      sx={{
        padding: 3,
        border: '1px solid #E0E0E0',
        borderRadius: 12,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      {/* Heading */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Registered Pets
      </Typography>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          overflowX: isSmallScreen ? 'auto' : 'hidden',
        }}
      >
        <Table>
          {!isSmallScreen && (
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Breed</strong>
                </TableCell>
                <TableCell>
                  <strong>Sex</strong>
                </TableCell>
                <TableCell>
                  <strong>Age</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {noPets ? (
              <TableRow>
                <TableCell colSpan={isSmallScreen ? 1 : 5} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No registered pets yet 🐕‍🦺
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              pets.map((pet) => (
                <TableRow
                  key={pet.id}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    },
                  }}
                  onClick={() => handleRowClick(pet.id)}
                >
                  {isSmallScreen ? (
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>
                          <strong>Name: </strong> {pet.name}
                        </Typography>
                        <Typography>
                          <strong>Type: </strong> {pet.type.type_name}
                        </Typography>
                        <Typography>
                          <strong>Breed: </strong> {pet.breed.breed_name}
                        </Typography>
                        <Typography>
                          <strong>Sex: </strong>{' '}
                          {pet.sex.toLowerCase() === 'f' ? 'Female' : 'Male'}
                        </Typography>
                        <Typography>
                          <strong>Age: </strong> {pet.age}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>{pet.name}</TableCell>
                      <TableCell>{pet.type.type_name}</TableCell>
                      <TableCell>{pet.breed.breed_name}</TableCell>
                      <TableCell>
                        {pet.sex.toLowerCase() === 'f' ? 'Female' : 'Male'}
                      </TableCell>
                      <TableCell>{pet.age}</TableCell>
                    </>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAddNewPet} // Handler passed from props
        >
          Add new Pet
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onAddCoOwnPet} // Handler passed from props
        >
          Add co-own Pet
        </Button>
      </Box>
    </Box>
  );
};

export default RegisteredPetIndex;

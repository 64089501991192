import React, { useEffect, useMemo, useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { supabase } from '../../supabaseClient';
import { CustomerBasic } from '../../types/customerTypes';
import SearchBar from '../SearchBar';
import { useAuth } from '../../contexts/AuthContext';

interface SelectOwnersDrawerProps {
  open: boolean;
  onClose: () => void;
  onSelectOwners: (owners: CustomerBasic[]) => void;
  preselectedOwnerId?: string;
}

/**
 * Displays a list of owners (customers) with checkboxes
 * to select which owners to attach to the new pet.
 */
const SelectOwnersDrawer: React.FC<SelectOwnersDrawerProps> = ({
  open,
  onClose,
  onSelectOwners,
  preselectedOwnerId,
}) => {
  const { userData } = useAuth();
  const [customers, setCustomers] = useState<CustomerBasic[]>([]);
  const [selected, setSelected] = useState<CustomerBasic[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!userData?.vet_company_id) return;

      try {
        const { data, error } = await supabase.rpc(
          'get_customers_for_vet_company',
          {
            p_vet_company_id: userData.vet_company_id,
          }
        );
        if (error) {
          console.error('Error fetching customers:', error);
        } else if (data) {
          console.log('Raw Customers Data:', data);
          const mappedData = data.map((c: any) => ({
            id: c.customer_id, // Match the RPC return structure
            name: c.name,
            surname: c.surname,
            email: c.email,
            phoneNumber: c.phone_number,
            pets:
              c.pets?.map((p: any) => ({
                id: p.id,
                name: p.name,
              })) || [], // Map pets if available
          })) as CustomerBasic[];
          console.log('Mapped Customers:', mappedData);
          setCustomers(mappedData);
        }
      } catch (err) {
        console.error('Unexpected error fetching customers:', err);
      }
    };

    if (open) {
      fetchCustomers();
      setSelected([]); // Reset selection each time we open
    }
  }, [open, userData?.vet_company_id]);

  useEffect(() => {
    if (!preselectedOwnerId) return;
    if (!open) return; // only do if drawer is open
    if (customers.length === 0) return; // not loaded yet

    // if we already have something selected, skip or override as you prefer
    if (selected.length > 0) return;

    // find the matching customer
    const match = customers.find((cust) => cust.id === preselectedOwnerId);
    if (match) {
      setSelected([match]);
    }
  }, [preselectedOwnerId, open, customers, selected]);

  const handleToggle = (customer: CustomerBasic) => {
    const exists = selected.find((s) => s.id === customer.id);
    if (exists) {
      // Remove from selection
      setSelected(selected.filter((s) => s.id !== customer.id));
    } else {
      // Add to selection
      setSelected([...selected, customer]);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredCustomers = useMemo(() => {
    const lowerCasedValue = searchTerm.toLowerCase();

    // Filter customers by search term
    const filtered = customers.filter((customer) => {
      const fullName = `${customer.name} ${customer.surname}`.toLowerCase();
      const phone = (customer.phoneNumber ?? '').toLowerCase();
      const email = (customer.email ?? '').toLowerCase();

      return (
        fullName.includes(lowerCasedValue) ||
        phone.includes(lowerCasedValue) ||
        email.includes(lowerCasedValue)
      );
    });

    // Sort: Checked rows first, then by name alphabetically
    return filtered.sort((a, b) => {
      const isASelected = selected.some((s) => s.id === a.id);
      const isBSelected = selected.some((s) => s.id === b.id);

      if (isASelected && !isBSelected) return -1;
      if (!isASelected && isBSelected) return 1;

      return a.name.localeCompare(b.name);
    });
  }, [searchTerm, customers, selected]);

  const handleNext = () => {
    onSelectOwners(selected);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: 600 },
          p: 3,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5">Select Owners</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Search */}
      <Box sx={{ mb: 2 }}>
        <SearchBar placeholder="Search for customer" onSearch={handleSearch} />
      </Box>

      {/* Table */}
      <TableContainer component={Paper} sx={{ maxHeight: 400, mb: 2 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers.map((cust) => {
              const isSelected = selected.some((s) => s.id === cust.id);
              return (
                <TableRow key={cust.id}>
                  <TableCell>
                    <Checkbox
                      checked={isSelected}
                      onChange={() => handleToggle(cust)}
                    />
                  </TableCell>
                  <TableCell>
                    {cust.name} {cust.surname}
                  </TableCell>
                  <TableCell>{cust.email}</TableCell>
                  <TableCell>{cust.phoneNumber}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Buttons */}
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={selected.length === 0} // Disable if no rows selected
        >
          Next Add Pet
        </Button>
      </Box>
    </Drawer>
  );
};

export default SelectOwnersDrawer;

import React from 'react';
import { Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
  const { sessionData, userData, signOut } = useAuth();

  // Function to handle sign-out
  const handleSignOut = async () => {
    await signOut();
    // Optionally redirect after sign-out
    // e.g., navigate('/sign-in'); if you're using react-router
  };
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.secondary">Veterinary</Typography>
        <Typography color="text.primary">Home</Typography>
      </Breadcrumbs>

      <Typography variant="h4">Home Page</Typography>
      <Typography>This is the Home page content.</Typography>

      {sessionData?.user?.email ? (
        <Stack spacing={2} mt={4}>
          <Typography>
            Logged in as: <strong>{sessionData.user.email}</strong>
          </Typography>
          <Typography>
            Role: <strong>{userData?.app_role}</strong>
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Sign Out
          </Button>
          <Button variant="contained" color="primary" component={Link} to="/">
            To Public Page
          </Button>
        </Stack>
      ) : (
        <Typography color="error" mt={4}>
          No user is logged in.
        </Typography>
      )}
    </>
  );
};

export default HomePage;

import { useEffect, useState } from 'react';

export function useScrollingUp() {
  const [scrollingUp, setScrollingUp] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      // If current scroll is less than the last scroll position => user is scrolling up
      setScrollingUp(currentScrollPos < lastScrollPos);
      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollPos]);

  return scrollingUp;
}

import React from 'react';
import { Box, Typography, Button } from '@mui/material';

interface ActionButtonsProps {
  pet_id?: string; // Make pet_id optional
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ pet_id }) => {
  const handleClick = (action: string) => {
    console.log(`${action} clicked for pet_id: ${pet_id}`);
  };

  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 4,
        border: '1px solid #E0E0E0',
        borderRadius: 6,
        boxShadow: 2,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', // Center text if pet_id is undefined
        gap: 1,
      }}
    >
      {pet_id ? (
        <>
          {/* Heading */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              marginBottom: 1,
              textAlign: 'flex-start',
            }}
          >
            Quick Actions
          </Typography>

          {/* Buttons */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleClick('New Case')}
          >
            New Case
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClick('New Appointment')}
          >
            New Appointment
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClick('New Estimate')}
          >
            New Estimate
          </Button>
        </>
      ) : (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          No pet is available
        </Typography>
      )}
    </Box>
  );
};

export default ActionButtons;

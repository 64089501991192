import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from '@mui/material';

interface BillingDocument {
  id: string;
  type: string;
  name: string;
  date: string; // ISO string or formatted date
}

interface BillingIndexProps {
  documents: BillingDocument[];
}

const BillingIndex: React.FC<BillingIndexProps> = ({ documents }) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  return (
    <Box
      sx={{
        padding: 3,
        border: '1px solid #E0E0E0',
        borderRadius: 12,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      {/* Heading */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Billing Documents
      </Typography>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          overflowX: isSmallScreen ? 'auto' : 'hidden',
        }}
      >
        <Table>
          {!isSmallScreen && (
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
                <TableCell>
                  <strong>ID</strong>
                </TableCell>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {documents.length === 0 ? (
              <TableRow>
                <TableCell colSpan={isSmallScreen ? 1 : 4} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No documents found 📄
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              documents.map((doc) => (
                <TableRow key={doc.id}>
                  {isSmallScreen ? (
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>
                          <strong>ID: </strong> {doc.id}
                        </Typography>
                        <Typography>
                          <strong>Type: </strong> {doc.type}
                        </Typography>
                        <Typography>
                          <strong>Name: </strong> {doc.name}
                        </Typography>
                        <Typography>
                          <strong>Date: </strong> {doc.date}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>{doc.id}</TableCell>
                      <TableCell>{doc.type}</TableCell>
                      <TableCell>{doc.name}</TableCell>
                      <TableCell>{doc.date}</TableCell>
                    </>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BillingIndex;

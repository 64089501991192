import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material'; // Icon for navigation
import { CustomerBasic } from '../../types/customerTypes';
import { useNavigate } from 'react-router-dom';

interface CustomerCardProps {
  customer: CustomerBasic;
}

const MiniCustomerCard: React.FC<CustomerCardProps> = ({ customer }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/app/customer-book/${customer.id}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Space between text and icon
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        padding: 2,
        marginBottom: 1,
        boxShadow: 'none', // Removed shadow
        backgroundColor: '#FDFBFB',
        overflow: 'hidden', // Hide overflow to prevent layout issues
        maxWidth: '100%', // Ensure it doesn't exceed container width
      }}
    >
      <Box
        sx={{
          flexGrow: 1, // Allow text content to take available space
          minWidth: 0, // Prevent the flex container from overflowing
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            wordWrap: 'break-word', // Ensure long text wraps
            whiteSpace: 'normal', // Allow text to break into multiple lines
          }}
        >
          {customer.name} {customer.surname}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          sx={{
            wordWrap: 'break-word', // Ensure long email wraps
            whiteSpace: 'normal',
          }}
        >
          {customer.email || 'N/A'}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {customer.phoneNumber || 'N/A'}
        </Typography>
      </Box>
      <IconButton
        onClick={handleNavigate}
        sx={{
          color: 'primary.main',
          marginLeft: 2, // Add spacing between text and icon
          flexShrink: 0, // Prevent the icon from shrinking
          '&:hover': {
            color: 'primary.dark',
          },
        }}
        aria-label={`Go to ${customer.name} ${customer.surname}'s profile`}
      >
        <ArrowForward />
      </IconButton>
    </Box>
  );
};

export default MiniCustomerCard;

import React, { useEffect, useMemo, useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { supabase } from '../../supabaseClient'; // or wherever you initialize supabase
import {
  Pet,
  AnimalType,
  Breed,
  Insurance,
  CustomerBasic,
} from '../../types/customerTypes';
import { toIsoDateString } from '../../utils/usableFunctions';

// ------------------------------------------------
// 1) Drawer Props
// ------------------------------------------------
interface AddPetDrawerProps {
  open: boolean;
  onClose: () => void;
  mode?: 'add' | 'edit';
  initialPet?: Pet;
  onSubmit: (pet: Pet) => void;
  owners: CustomerBasic[];
}

// ------------------------------------------------
// 2) Form Data
// We'll store 'type', 'breed', 'insuranceCompany' as actual objects (not null).
// We'll also add policyNumber, passportId, chip as optional strings
// (they can be empty or null).
// ------------------------------------------------
interface PetFormData extends Omit<Pet, 'weight' | 'owners'> {
  type: AnimalType;
  breed: Breed;
  optionalWeight: number | null;
  owners: Array<Omit<CustomerBasic, 'pets'>>;
}

// ------------------------------------------------
// 3) Yup Schema
// - name, birthDate, sex, neutered are required
// - type, breed must be valid objects (not null).
// - insurance is optional, so we allow null
// ------------------------------------------------
const petSchema = yup.object({
  id: yup.string(),
  name: yup.string().required('Pet Name is required'),

  birthDate: yup
    .date()
    .typeError('Invalid date')
    .required('Birth date is required')
    .test(
      'isNotDefaultToday',
      'Please select a valid birth date',
      (value, context) => {
        if (!value) return false; // Ensure the value exists
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize to midnight

        const inputValue = context.originalValue;
        const isExplicitToday =
          value.getTime() === today.getTime() && inputValue !== undefined;
        return value.getTime() < today.getTime() || isExplicitToday;
      }
    ),
  sex: yup.string().required('Sex is required'),
  neutered: yup.boolean().required(),

  policyNumber: yup.string().nullable(),
  passportId: yup.string().nullable(),
  chip: yup.string().nullable(),

  type: yup
    .mixed<AnimalType>()
    .test('typeCheck', 'Please select an animal type', (value) => {
      return value && !!value.id; // Must have an id
    })
    .required(),
  breed: yup
    .mixed<Breed>()
    .test('breedCheck', 'Please select a breed', (value) => {
      return value && !!value.id;
    })
    .required(),
  insuranceCompany: yup
    .mixed<Insurance>()
    .nullable() // user might not have insurance => if you want it mandatory, remove .nullable()
    .notRequired()
    .defined()
    .test('insuranceCheck', 'Please select a valid insurance', (value) => {
      // If user picks nothing, value = null => that's allowed if not mandatory
      if (!value) return true;
      return !!value.id;
    }),
  optionalWeight: yup
    .number()
    .nullable()
    .typeError('Weight must be a number')
    .notRequired()
    .defined()
    // If you want a positive check:
    .min(0, 'Weight cannot be negative'),
  owners: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
        surname: yup.string().required(),
        email: yup.string().nullable().defined(),
        phoneNumber: yup.string().nullable().defined(),
      })
    )
    .required(),
});

// ------------------------------------------------
// 4) Component
// ------------------------------------------------
const AddPetDrawer: React.FC<AddPetDrawerProps> = ({
  open,
  onClose,
  mode = 'add',
  initialPet,
  onSubmit,
  owners,
}) => {
  // Title depends on mode
  const title = mode === 'edit' ? 'Edit Pet' : 'Add New Pet';

  // ---------------------------------------------
  // 4A) Local state for DB data
  // ---------------------------------------------
  const [animalTypes, setAnimalTypes] = useState<AnimalType[]>([]);
  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [insurances, setInsurances] = useState<Insurance[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1) Fetch animal types
        const { data: typesData, error: typesError } = await supabase
          .from('animal_types')
          .select('*')
          .order('type_name', { ascending: true });
        if (typesError) throw typesError;
        setAnimalTypes(typesData || []);

        // 2) Fetch breeds
        const { data: breedsData, error: breedsError } = await supabase
          .from('breeds')
          .select('*')
          .order('breed_name', { ascending: true });
        if (breedsError) throw breedsError;
        setBreeds(breedsData || []);

        // 3) Fetch insurances
        const { data: insuranceData, error: insuranceError } = await supabase
          .from('insurance_companies')
          .select('*')
          .order('name', { ascending: true });
        if (insuranceError) throw insuranceError;
        setInsurances(insuranceData || []);
      } catch (err: any) {
        console.error('Error fetching data:', err.message);
      }
    };

    fetchData();
  }, []);

  // ---------------------------------------------
  // 4B) Prepare default form values
  // We want type/breed to never be null. So:
  //   - If edit mode, use the existing data
  //   - If add mode, pick the "first" from the arrays, or fallback to dummy objects
  // ---------------------------------------------
  const defaultValues: PetFormData = useMemo(() => {
    if (mode === 'edit' && initialPet) {
      return {
        ...initialPet,
        insuranceCompany: initialPet.insuranceCompany || null,
        policyNumber: initialPet.policyNumber || null,
        passportId: initialPet.passportId || null,
        chip: initialPet.chip || null,
        birthDate: initialPet.birthDate
          ? new Date(initialPet.birthDate)
          : new Date(),
        // type & breed from initialPet are guaranteed non-null if DB enforces it
        type: initialPet.type || null,
        breed: initialPet.breed || null,
        optionalWeight: null,
      };
    }

    // If adding a new pet (We can't pick the first items yet until arrays are loaded)
    // We'll store temporary placeholders. We'll "fix" them in a useEffect once data is loaded.
    return {
      id: undefined,
      name: '',
      birthDate: new Date(),
      sex: '',
      neutered: false,
      chip: '',
      policyNumber: '',
      passportId: '',
      insuranceCompany: null,
      type: {
        id: '',
        type_name: '',
      },
      breed: {
        id: '',
        breed_name: '',
        animal_type_id: '',
      },
      optionalWeight: 0,
      owners: owners || [],
    };
  }, [mode, initialPet, owners]);

  // ---------------------------------------------
  // 5) React Hook Form
  // ---------------------------------------------
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PetFormData>({
    defaultValues,
    resolver: yupResolver(petSchema),
    mode: 'onSubmit',
  });

  // Reset form whenever drawer opens or defaults change
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open, defaultValues, reset]);

  // ---------------------------------------------
  // 6) Dependent logic
  // ---------------------------------------------
  const selectedType = watch('type'); // AnimalType
  const selectedBreed = watch('breed'); // Breed

  // Filtered breed list
  const filteredBreeds = useMemo(() => {
    if (!selectedType?.id) return breeds;
    return breeds.filter((b) => b.animal_type_id === selectedType.id);
  }, [selectedType, breeds]);

  // Reset breed to the default value when type is reset
  useEffect(() => {
    if (!selectedType?.id) {
      // Reset breed to the default value from `defaultValues`
      setValue('breed', defaultValues.breed);
    }
  }, [selectedType, setValue, defaultValues.breed]);

  // If user picks a breed that has a known type => auto-sync type
  useEffect(() => {
    if (selectedBreed?.animal_type_id) {
      const foundType = animalTypes.find(
        (t) => t.id === selectedBreed.animal_type_id
      );
      if (foundType && foundType.id !== selectedType?.id) {
        setValue('type', foundType);
      }
    }
  }, [selectedBreed, animalTypes, selectedType, setValue]);

  // ---------------------------------------------
  // 7) On form submit => build final Pet
  // ---------------------------------------------

  const onFormSubmit = (formData: PetFormData) => {
    const { optionalWeight, ...rest } = formData;

    const payload: Pet = {
      ...rest,
      birthDate: new Date(rest.birthDate),
      // If user entered a weight, create a Weight[] with date, else empty
      weight:
        optionalWeight != null
          ? [
              {
                weight: optionalWeight,
                date: new Date(),
              },
            ]
          : [],
    };

    onSubmit(payload);
    handleClose();
  };

  // ---------------------------------------------
  // 8) Close logic
  // ---------------------------------------------
  const handleClose = () => {
    reset();
    onClose();
  };

  // ---------------------------------------------
  // 9) Render
  // ---------------------------------------------
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: 480 },
          p: 3,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5">{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <Box display="flex" gap={2}>
            {/* Pet Name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Pet Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />

            {/* Birth Date */}
            <Controller
              name="birthDate"
              control={control}
              render={({ field }) => (
                <TextField
                  // Convert the Date object to a yyyy-MM-dd string
                  value={field.value ? toIsoDateString(field.value) : ''}
                  onChange={(e) => {
                    // Convert the string back to a Date object
                    const newVal = e.target.value
                      ? new Date(e.target.value)
                      : new Date();
                    field.onChange(newVal);
                  }}
                  type="date"
                  label="Birth Date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.birthDate}
                  helperText={errors.birthDate?.message}
                />
              )}
            />
          </Box>

          {/* Insurance & Policy Number*/}
          <Box display="flex" gap={2}>
            <Controller
              name="insuranceCompany"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={insurances}
                  getOptionLabel={(opt) => opt.name}
                  value={field.value || null}
                  onChange={(_, newVal) => field.onChange(newVal)}
                  fullWidth
                  freeSolo={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Insurance"
                      placeholder="Select Insurance"
                    />
                  )}
                />
              )}
            />

            <Controller
              name="policyNumber"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Policy Number" fullWidth />
              )}
            />
          </Box>

          <Box display="flex" gap={2}>
            {/* Passport ID */}
            <Controller
              name="passportId"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Passport ID" fullWidth />
              )}
            />

            {/* Chip */}
            <Controller
              name="chip"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Microchip ID" fullWidth />
              )}
            />
          </Box>

          {/* Animal Type */}
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={animalTypes}
                getOptionLabel={(opt) => opt.type_name}
                value={field.value} // always an object
                onChange={(_, newVal) => field.onChange(newVal)}
                freeSolo={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Animal Type"
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                )}
              />
            )}
          />

          {/* Breed */}
          <Controller
            name="breed"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={filteredBreeds}
                getOptionLabel={(opt) => opt.breed_name}
                value={field.value} // always an object
                onChange={(_, newVal) => field.onChange(newVal)}
                freeSolo={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Breed"
                    error={!!errors.breed}
                    helperText={errors.breed?.message}
                  />
                )}
              />
            )}
          />
          {/* Add Initial Weight */}
          {mode === 'add' && (
            <Controller
              name="optionalWeight"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Initial Weight (kg)"
                  type="number"
                  fullWidth
                  error={!!errors.optionalWeight}
                  helperText={errors.optionalWeight?.message}
                  sx={{
                    '& input[type=number]': {
                      MozAppearance: 'textfield', // For Firefox
                    },
                    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                      {
                        WebkitAppearance: 'none', // For Chrome, Safari, Edge
                        margin: 0,
                      },
                  }}
                />
              )}
            />
          )}

          <Box display="flex" gap={2}>
            {/* Sex & Neutered */}

            <Controller
              name="sex"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Sex
                  </Typography>
                  <RadioGroup
                    row
                    value={field.value || ''}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <FormControlLabel
                      value="f"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="m"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                  <Typography color="error" variant="caption">
                    {errors.sex?.message}
                  </Typography>
                </FormControl>
              )}
            />

            <Controller
              name="neutered"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Neutered
                  </Typography>
                  <RadioGroup
                    row
                    value={field.value ? 'true' : 'false'}
                    onChange={(e) => field.onChange(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <Typography color="error" variant="caption">
                    {errors.neutered?.message}
                  </Typography>
                </FormControl>
              )}
            />
          </Box>

          {/* Show owners (add mode and read-only) */}
          {mode === 'add' && (
            <Box>
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Registered Owners
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch('owners').map((owner) => (
                    <TableRow key={owner.id}>
                      <TableCell>
                        {owner.name} {owner.surname}
                      </TableCell>
                      <TableCell>{owner.email}</TableCell>
                      <TableCell>{owner.phoneNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {/* Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mb={2} mt={2}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {mode === 'edit' ? 'Save Changes' : 'Save Pet'}
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};

export default AddPetDrawer;

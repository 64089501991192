import React, { useEffect, useState } from 'react';
import { CustomerBasic, Pet } from '../../types/customerTypes';
import SelectOwnersDrawer from '../SelectOwnersDrawer';
import AddPetDrawer from '../AddPetDrawer';

interface AddPetFlowProps {
  open: boolean;
  mode: 'add' | 'edit';
  initialPet?: Pet; // If editing, we have an existing pet
  onClose: () => void; // Called when the entire flow should close
  onSave: (pet: Pet) => void; // Called when the pet is finally saved
  preselectedOwnerId?: string;
}

const AddPetFlow: React.FC<AddPetFlowProps> = ({
  open,
  mode,
  initialPet,
  onClose,
  onSave,
  preselectedOwnerId,
}) => {
  // Step 1: owners drawer (only used in "add" mode)
  const [showOwnersDrawer, setShowOwnersDrawer] = useState(false);

  // Step 2: pet form drawer
  const [showPetForm, setShowPetForm] = useState(false);

  // The selected owners for a new pet
  const [owners, setOwners] = useState<CustomerBasic[]>([]);

  // If editing, we might already have owners in the pet
  // so we can default them from initialPet.owners
  useEffect(() => {
    if (mode === 'edit' && initialPet?.owners) {
      setOwners(initialPet.owners);
    }
  }, [mode, initialPet]);

  // Whenever `open` changes, we either start the flow or reset everything
  useEffect(() => {
    if (!open) {
      // flow closed => reset
      setShowOwnersDrawer(false);
      setShowPetForm(false);
      setOwners([]);
      return;
    }

    // If opening in ADD mode => show the owners drawer first
    // If editing => go straight to the pet form
    if (mode === 'add') {
      setShowOwnersDrawer(true);
      setShowPetForm(false);
    } else {
      // edit mode => skip owners step, show pet form
      setShowOwnersDrawer(false);
      setShowPetForm(true);
    }
  }, [open, mode]);

  // Called after user picks owners in add mode
  const handleSelectOwners = (selected: CustomerBasic[]) => {
    setOwners(selected);
    setShowOwnersDrawer(false);
    setShowPetForm(true);
  };

  // Called when user saves the new or edited pet
  const handlePetSave = (newPet: Pet) => {
    // The user might have used 'owners' from state or from an existing pet
    onSave(newPet);
    // flow ends
    handleCloseFlow();
  };

  // Closes the entire flow
  const handleCloseFlow = () => {
    setShowOwnersDrawer(false);
    setShowPetForm(false);
    setOwners([]);
    onClose(); // parent callback
  };

  return (
    <>
      {/* Step 1: Select owners (only in add mode) */}
      <SelectOwnersDrawer
        open={showOwnersDrawer}
        onClose={handleCloseFlow}
        onSelectOwners={handleSelectOwners}
        preselectedOwnerId={preselectedOwnerId}
      />

      {/* Step 2: Pet Form */}
      <AddPetDrawer
        open={showPetForm}
        onClose={handleCloseFlow}
        owners={owners} // pass the selected owners
        onSubmit={handlePetSave} // final save callback
        initialPet={initialPet} // if edit, pass the existing pet
        mode={mode} // 'add' or 'edit'
      />
    </>
  );
};

export default AddPetFlow;

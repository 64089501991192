import React, { createContext, useContext, useEffect, useState } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../supabaseClient';
import {
  AuthContextProps,
  UserData,
  VetCompanyData,
} from '../types/userAuthTypes';

const AuthContext = createContext<AuthContextProps>({
  sessionData: null,
  userData: null,
  vetCompanyData: null,
  loading: true,
  signUp: async () => ({ error: null }),
  signIn: async () => ({ error: null }),
  signOut: async () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sessionData, setSessionData] = useState<Session | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [vetCompanyData, setVetCompanyData] = useState<VetCompanyData | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  // 1. On mount, check for current session and subscribe to changes
  useEffect(() => {
    // Check if there's an existing session
    supabase.auth.getSession().then(({ data }) => {
      if (data.session) {
        setSessionData(data.session);
        // Fetch user data immediately if session exists
        fetchUserData(data.session.user?.id);
      }
      setLoading(false);
    });

    // Subscribe to any session/auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, newSession) => {
      setSessionData(newSession);

      // If user is logged in, fetch user data; otherwise clear it
      if (newSession?.user?.id) {
        fetchUserData(newSession.user.id);
      } else {
        setUserData(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 2a. Fetch user data from public.user_profiles by userId
  const fetchUserData = async (userId?: string) => {
    if (!userId) return;

    const { data, error } = await supabase
      .from('user_profiles')
      .select(
        'id, email, phone_number, app_role, vet_company_id, preferred_language_id'
      )
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching user data:', error.message);
      setUserData(null);
      return;
    }

    setUserData(data);

    // Fetch vet company data if vet_company_id exists
    if (data.vet_company_id) {
      await fetchVetCompanyData(data.vet_company_id);
    }
  };

  // 2b. Fetch user data from public.user_profiles by userId
  const fetchVetCompanyData = async (vetCompanyId?: string) => {
    if (!vetCompanyId) return;

    const { data, error } = await supabase
      .from('vet_companies')
      .select('id, name, country')
      .eq('id', vetCompanyId)
      .single();

    if (error) {
      console.error('Error fetching vet company data:', error.message);
      setVetCompanyData(null);
    } else {
      setVetCompanyData(data);
    }
  };

  // 3. Sign Up (email + password)
  const signUp = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    const { data: signUpData, error: signUpError } = await supabase.auth.signUp(
      {
        email,
        password,
      }
    );

    // If there's an error creating the account
    if (signUpError) {
      setLoading(false);
      return { error: signUpError };
    }

    console.log('signUpData', signUpData);

    // If email confirmations are off, user is automatically logged in
    // => onAuthStateChange => fetchUserData
    setLoading(false);
    return { error: null };
  };

  // 4. Sign In (email + password)
  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    console.log('After signIn => data.session:', data.session);
    setLoading(false);

    if (error) {
      return { error };
    }
    return { error: null };
  };

  // 5. Sign Out
  const signOut = async () => {
    setLoading(true);
    await supabase.auth.signOut();
    setSessionData(null);
    setUserData(null);
    setLoading(false);
  };

  // Provide everything in context
  const value: AuthContextProps = {
    sessionData,
    userData,
    vetCompanyData,
    loading,
    signUp,
    signIn,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to consume the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

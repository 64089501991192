// BasePublicLayout.tsx
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useScrollingUp } from '../../hooks/useScrollingUp';
import { useAuth } from '../../contexts/AuthContext';

interface PublicLayoutProps {
  children: React.ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  const scrollingUp = useScrollingUp();
  const { sessionData } = useAuth();

  console.log('sessionData in PublicLayout:', sessionData);

  return (
    <>
      {/* Top Navigation Bar */}
      <AppBar
        position="fixed"
        sx={{
          // Animate showing/hiding with transform
          transition: 'transform 0.3s ease-in-out',
          transform: scrollingUp ? 'translateY(0)' : 'translateY(-110%)',
        }}
      >
        <Toolbar>
          {/* Left-Aligned: VetJ */}
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
            component={Link}
            to="/"
          >
            VetJ
          </Typography>

          {/* Conditionally Render "To App" Button */}
          {sessionData ? (
            <Button color="inherit" component={Link} to="/app/home">
              To App
            </Button>
          ) : (
            <>
              {/* Sign In / Create Account Buttons */}
              <Button color="inherit" component={Link} to="/sign-in">
                Sign In
              </Button>
              <Button color="inherit" component={Link} to="/create-account">
                Create Account
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      {/*
        This <Toolbar /> creates space equal to the AppBar's height,
        preventing content from sliding under it.
      */}
      <Toolbar />

      {/* Main Content Area */}
      <Box sx={{ p: 2 }}>{children}</Box>
    </>
  );
};

export default PublicLayout;

// src/pages/public/ResetPasswordPage.tsx

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Stack,
  Link as MuiLink,
} from '@mui/material';
import MuiCard from '@mui/material/Card';
import { supabase } from '../../../supabaseClient';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
}));

const ResetPasswordContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('access_token') || '';

  const [newPassword, setNewPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  useEffect(() => {
    // If there's an access token in the URL, supabase can use it
    // to sign in the user so we can call updateUser
    if (accessToken) {
      supabase.auth
        .setSession({
          access_token: accessToken,
          refresh_token: '',
        })
        .then(({ error }) => {
          if (error) {
            setErrorMsg(error.message);
          }
        });
    } else {
      setErrorMsg('No reset token found. Please request a new link.');
    }
  }, [accessToken]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMsg('');
    setSuccessMsg('');

    if (!newPassword) {
      setErrorMsg('Please enter a new password.');
      return;
    }

    // Now that we're signed in with the recovery token, update password
    const { error } = await supabase.auth.updateUser({ password: newPassword });
    if (error) {
      setErrorMsg(error.message);
      return;
    }

    setSuccessMsg('Your password has been updated successfully!');
  };

  return (
    <>
      <CssBaseline />
      <ResetPasswordContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Reset Password
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            {errorMsg && (
              <Typography color="error" variant="body2">
                {errorMsg}
              </Typography>
            )}
            {successMsg && (
              <Typography color="primary" variant="body2">
                {successMsg}
              </Typography>
            )}

            <TextField
              type="password"
              label="New Password"
              variant="outlined"
              fullWidth
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={!accessToken}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!accessToken}
            >
              Update Password
            </Button>

            <Typography sx={{ textAlign: 'center' }}>
              <MuiLink href="/sign-in" variant="body2">
                Back to Sign In
              </MuiLink>
            </Typography>
          </Box>
        </Card>
      </ResetPasswordContainer>
    </>
  );
};

export default ResetPasswordPage;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Breadcrumbs, useMediaQuery } from '@mui/material';
import MiniNavPanel from '../../../components/MiniNav';
import { supabase } from '../../../supabaseClient';
import { Pet } from '../../../types/customerTypes';
import { Journal } from '../../../types/journalTypes';
import { styled } from '@mui/system';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import InformationPanel from '../../../components/JournalDetailsModules/InformationPanel';
import JournalPanel from '../../../components/JournalPanel';
import JournalInput from '../../../components/JournalInput';
import { useAuth } from '../../../contexts/AuthContext';

const ModuleContainer = styled(Box)(() => ({
  borderRadius: 16,
  backgroundColor: '#FAFAFA',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: 12,
  flex: 1,
  minWidth: 0,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
}));

const ModuleHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 4,
  paddingTop: 8,
  paddingBottom: 8,
  borderBottom: '2px solid #D9D9D9',
}));

// This is the content area, scrollable
const ModuleContent = styled(Box)(() => ({
  flex: 1, // take remaining vertical space
  overflowY: 'auto', // independent scroll
}));

interface JournalDetailsPageProps {
  // if needed, define props
}

const JournalDetailsPage: React.FC<JournalDetailsPageProps> = () => {
  const isSmallScreen = useMediaQuery('(max-width:1260px)');

  const { sessionData } = useAuth();
  const { journalId } = useParams();
  const [pet, setPet] = useState<Pet | null>(null);
  const [loading, setLoading] = useState(true);
  const [journalData, setJournalData] = useState<Journal | null>(null);

  // Minimization states
  const [isLPMin, setLPMin] = useState(false); // left panel minimized?
  const [isRPMin, setRPMin] = useState(false); // right panel minimized?

  useEffect(() => {
    const fetchJournalAndPetDetails = async () => {
      if (!journalId) return;

      try {
        // Fetch journal data
        const { data: journal, error: journalError } = await supabase.rpc(
          'get_journal_by_id',
          { p_journal_id: journalId }
        );

        if (journalError) {
          console.error('Error fetching journal details:', journalError);
          setLoading(false);
          return;
        }

        if (!journal) {
          console.warn('No journal data returned.');
          setLoading(false);
          return;
        }

        setJournalData(journal);

        // Extract patientId (petId) from the journal data
        const patientId = journal.patientId;
        if (!patientId) {
          console.warn('No patientId associated with this journal.');
          setLoading(false);
          return;
        }

        // Fetch pet details using patientId
        const { data: petData, error: petError } = await supabase.rpc(
          'get_pet_by_id',
          { p_pet_id: patientId }
        );

        if (petError) {
          console.error('Error fetching pet details:', petError);
          setLoading(false);
          return;
        }

        if (!petData) {
          console.warn('No pet data returned.');
          setLoading(false);
          return;
        }

        // Map pet data to the `Pet` type
        const mappedPet: Pet = {
          id: petData.id,
          name: petData.name,
          insuranceCompany: petData.insuranceCompany?.id
            ? {
                id: petData.insuranceCompany.id,
                name: petData.insuranceCompany.name,
              }
            : null,
          policyNumber: petData.policyNumber || null,
          passportId: petData.passportId || null,
          type: {
            id: petData.type.id,
            type_name: petData.type.type_name,
          },
          breed: {
            id: petData.breed.id,
            breed_name: petData.breed.breed_name,
          },
          chip: petData.chip || null,
          birthDate: petData.birthDate,
          age: petData.age,
          sex: petData.sex,
          neutered: petData.neutered,
          weight: petData.weight || [],
          owners: petData.owners?.map((owner: any) => ({
            id: owner.id,
            name: owner.name,
            surname: owner.surname,
            email: owner.email || null,
            phoneNumber: owner.phoneNumber || null,
          })),
        };

        setPet(mappedPet);
      } catch (err) {
        console.error(
          'Unexpected error fetching journal and pet details:',
          err
        );
      } finally {
        setLoading(false);
      }
    };

    fetchJournalAndPetDetails();
  }, [journalId]);

  if (loading) {
    return <Typography>Loading Journal data...</Typography>;
  }

  if (!journalData) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        No journal data available.
      </Typography>
    );
  }

  if (!pet) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        No pet data available.
      </Typography>
    );
  }

  if (!journalId) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        Journal ID is missing.
      </Typography>
    );
  }

  if (!sessionData) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        Session Data is missing.
      </Typography>
    );
  }

  const handleEditPet = () => {
    console.log('Editpet clicked');
  };

  // For demonstration, we just define minimal placeholders

  const ToolsModuleContent = <Typography>Tools Content (WIP)</Typography>;

  // If small screen, we render a single component with MiniNav
  // If large screen, we just show placeholders for each section
  return (
    <Box>
      {/* 4) Top of the page: Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.secondary">Veterinary</Typography>
        <Typography color="text.secondary">Journal Management</Typography>
        <Typography color="text.primary">{`Journal-ID ${journalData.publicJournalId}`}</Typography>
      </Breadcrumbs>

      {/* 1) The page has 3 sections: "Information", "Journal", "Tools" */}
      {isSmallScreen ? (
        // 2) For small screen => single screen with MiniNav
        <MiniNavPanel
          defaultSelected={0}
          items={[
            {
              label: 'Information',
              content: <InformationPanel pet={pet} onEdit={handleEditPet} />,
            },
            {
              label: 'Journal',
              content: (
                <JournalInput
                  userId={sessionData?.user.id}
                  journalId={journalId}
                />
              ),
            },
            {
              label: 'Tools',
              content: ToolsModuleContent,
            },
          ]}
        />
      ) : (
        // 3) For big screen => just show text placeholders for now
        <Box
          display="flex"
          flexDirection="row"
          gap={3}
          height="calc(100vh - 145px)"
        >
          {/* NEW Left Panel (LP) */}
          <JournalPanel
            title="Information"
            icon={<KeyboardDoubleArrowRight />}
            isMinimized={isLPMin}
            onToggleMinimized={() => setLPMin((prev) => !prev)}
          >
            {
              <InformationPanel
                pet={pet}
                onEdit={handleEditPet}
                isMinimized={isLPMin}
                onRestore={() => setLPMin(false)}
              />
            }
          </JournalPanel>

          {/* Center Body (CB) */}
          <ModuleContainer>
            <ModuleHeader>
              <Typography variant="h6">Journal</Typography>
            </ModuleHeader>
            <ModuleContent>
              <JournalInput
                userId={sessionData?.user.id}
                journalId={journalId}
              />
            </ModuleContent>
          </ModuleContainer>

          {/* NEW Right Panel (RP) */}
          <JournalPanel
            title="Tools"
            icon={<KeyboardDoubleArrowLeft />}
            isMinimized={isRPMin}
            onToggleMinimized={() => setRPMin((prev) => !prev)}
          >
            {
              <InformationPanel
                pet={pet}
                onEdit={handleEditPet}
                isMinimized={isRPMin}
                onRestore={() => setRPMin(false)}
              />
            }
          </JournalPanel>
        </Box>
      )}
    </Box>
  );
};

export default JournalDetailsPage;

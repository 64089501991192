import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { JournalBasic } from '../../types/journalTypes';

interface JournalIndexProps {
  entries: JournalBasic[];
}

/**
 * JournalIndex:
 * Similar style to BillingIndex but with columns:
 * Status, Type, Name, Date, Veterinary
 */
const JournalIndex: React.FC<JournalIndexProps> = ({ entries }) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  return (
    <Box
      sx={{
        padding: 3,
        border: '1px solid #E0E0E0',
        borderRadius: 6,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      {/* Heading */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Journal Entries
      </Typography>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          overflowX: isSmallScreen ? 'auto' : 'hidden',
        }}
      >
        <Table>
          {/* If not small screen, show column headers */}
          {!isSmallScreen && (
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
                <TableCell>
                  <strong>Status</strong>
                </TableCell>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Title</strong>
                </TableCell>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Veterinary</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {entries.length === 0 ? (
              <TableRow>
                <TableCell colSpan={isSmallScreen ? 1 : 5} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No journal entries found 📔
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              entries.map((item) => (
                <TableRow key={item.id}>
                  {isSmallScreen ? (
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>
                          <strong>Status: </strong>{' '}
                          <Typography
                            component="span"
                            sx={{
                              color:
                                item.status === 'Open' ? 'orange' : 'green',
                            }}
                          >
                            {item.status}
                          </Typography>
                        </Typography>
                        <Typography>
                          <strong>Type: </strong> {item.type}
                        </Typography>
                        <Typography>
                          <strong>Title: </strong> {item.title}
                        </Typography>
                        <Typography>
                          <strong>Date: </strong> {item.date}
                        </Typography>
                        <Typography>
                          <strong>Veterinary: </strong> {item.mainVet}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>
                        <Typography
                          sx={{
                            color: item.status === 'Open' ? 'orange' : 'green',
                          }}
                        >
                          {item.status}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell>{item.mainVet}</TableCell>
                    </>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default JournalIndex;

import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: 12,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[400]}`,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
}));

const SearchInput = styled('input')(({ theme }) => ({
  flex: 1,
  border: 'none',
  outline: 'none',
  fontSize: '1rem',
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
}));

const IconButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
}));

interface SearchBarProps {
  placeholder?: string;
  onSearch: (value: string) => void;
  debounceDelay?: number;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = 'Search...',
  onSearch,
  debounceDelay = 300,
}) => {
  const [value, setValue] = useState('');

  // Debounced search function
  const debounce = (func: Function, delay: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearch = debounce(onSearch, debounceDelay);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Memoize handleReset to avoid triggering re-renders
  const handleReset = useCallback(() => {
    setValue('');
    onSearch('');
  }, [onSearch]);

  // Memoize handleKeyDown to avoid stale closures
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleReset();
      }
    },
    [handleReset]
  );

  // Attach keydown listener for Escape
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <SearchContainer>
      <SearchInput
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      <IconButton
        onClick={value ? handleReset : undefined}
        aria-label={value ? 'clear' : 'search'}
      >
        {value ? <CloseIcon /> : <SearchIcon />}
      </IconButton>
    </SearchContainer>
  );
};

export default SearchBar;

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';

const ModuleContainer = styled(Box)<{ isMinimized?: boolean }>(
  ({ isMinimized }) => ({
    borderRadius: 16,
    backgroundColor: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: isMinimized ? 4 : 12, // Smaller padding if minimized?
    transition: 'width 0.3s ease', // So width changes smoothly
    overflow: 'hidden', // Hide content if we reduce width drastically
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  })
);

const ModuleHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '2px solid #D9D9D9',
  marginBottom: 4,
}));

const ModuleContent = styled(Box)(() => ({
  flex: 1,
  overflowY: 'auto',
}));

// Props for the JournalModule
interface JournalPanelProps {
  title: string;
  icon: React.ReactNode;
  isMinimized: boolean;
  onToggleMinimized: () => void;
  children: React.ReactNode;
}

const JournalPanel: React.FC<JournalPanelProps> = ({
  title,
  icon,
  isMinimized,
  onToggleMinimized,
  children,
}) => {
  return (
    <ModuleContainer
      sx={{ width: isMinimized ? 50 : 300 }}
      isMinimized={isMinimized}
    >
      <ModuleHeader
        sx={{
          justifyContent: isMinimized ? 'center' : 'space-between',
        }}
      >
        {/* If minimized, we hide the title */}
        {isMinimized ? null : (
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>
            {title}
          </Typography>
        )}

        {/* Icon can be clickable to toggle */}
        <IconButton onClick={onToggleMinimized} size="large">
          {icon}
        </IconButton>
      </ModuleHeader>

      {/* The scrollable content is hidden if minimized */}
      <ModuleContent>{children}</ModuleContent>
    </ModuleContainer>
  );
};

export default JournalPanel;

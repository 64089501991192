import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Typography, Box, Stack, Button } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import { Pets } from '@mui/icons-material';
import PetsTable from '../../../components/PetsTable';
import { Pet, PetBasic } from '../../../types/customerTypes';
import AddPetFlow from '../../../components/AddPetFlow';
import { useAuth } from '../../../contexts/AuthContext';
import { supabase } from '../../../supabaseClient';

const PetDirectoryPage: React.FC = () => {
  const { userData } = useAuth();
  const [pets, setPets] = useState<PetBasic[]>([]);
  const [filteredPets, setFilteredPets] = useState<PetBasic[]>([]);
  const [flowOpen, setFlowOpen] = useState(false);

  // Fetch pets for the vet company on component mount
  useEffect(() => {
    const fetchPets = async () => {
      if (!userData?.vet_company_id) return;

      try {
        const { data, error } = await supabase.rpc('get_pets_for_vet_company', {
          p_vet_company_id: userData.vet_company_id,
        });
        if (error) {
          console.error('Error fetching pets:', error);
        } else if (data) {
          // console.log('Raw Pets Data:', data);
          const mappedPets = data.map((pet: any) => ({
            id: pet.id,
            name: pet.name,
            type: {
              id: pet.type.id,
              type_name: pet.type.name,
            },
            breed: {
              id: pet.breed.id,
              breed_name: pet.breed.name,
            },
            sex: pet.sex,
            age: pet.age,
            owners: pet.owners?.map((owner: any) => ({
              id: owner.id,
              name: owner.name,
              surname: owner.surname,
            })),
          }));
          //  console.log('Mapped Pets:', mappedPets);
          setPets(mappedPets);
          setFilteredPets(mappedPets);
        }
      } catch (err) {
        console.error('Unexpected error fetching pets:', err);
      }
    };

    fetchPets();
  }, [userData?.vet_company_id]);

  const handleAddNew = () => {
    setFlowOpen(true);
  };

  const handleCloseFlow = () => {
    setFlowOpen(false);
  };

  const handleSavePet = async (newPet: Pet) => {
    try {
      if (!userData?.vet_company_id) {
        throw new Error('Vet company ID not found in user context');
      }

      // Log the data before submission
      //   console.log('Data to be submitted:', newPet);

      // Extract weight
      const optionalWeight =
        newPet.weight && newPet.weight.length > 0
          ? newPet.weight[0].weight
          : null;

      // Extract owner IDs
      const ownerIds = newPet.owners.map((owner) => owner.id);

      // Call the `add_pet` RPC function
      const { data, error } = await supabase.rpc('add_pet', {
        p_name: newPet.name,
        p_type: newPet.type.id,
        p_breed: newPet.breed.id,
        p_chip_number: newPet.chip || null,
        p_birth_date: new Date(newPet.birthDate).toISOString().split('T')[0],
        p_sex: newPet.sex,
        p_neutered: newPet.neutered,
        p_weight: optionalWeight,
        p_insurance_company_id: newPet.insuranceCompany?.id || null,
        p_customer_ids: ownerIds, // array of UUIDs
        p_vet_company_id: userData.vet_company_id,
        p_policy_number: newPet.policyNumber || null,
        p_passport_number: newPet.passportId || null,
      });

      if (error) {
        console.error('Error adding pet:', error);
        return;
      }

      //console.log('add_pet RPC result =>', data);

      if (!data) {
        console.warn('No data returned from add_pet RPC');
        return;
      }

      // Parse the response to create the new pet
      const createdPet: PetBasic = {
        id: data.id,
        name: data.name,
        type: {
          id: data.type.id,
          type_name: data.type.type_name,
        },
        breed: {
          id: data.breed.id,
          breed_name: data.breed.breed_name,
        },
        sex: data.sex,
        age: data.age,
        owners: data.owners.map((owner: any) => ({
          id: owner.id,
          name: owner.name,
          surname: owner.surname,
        })),
      };

      // Update local state with the new pet
      setPets((prev) => [...prev, createdPet]);
      setFilteredPets((prev) => [...prev, createdPet]);

      //  console.log('Created pet =>', createdPet);
    } catch (err) {
      console.error('Unexpected error saving pet:', err);
    } finally {
      // Close the drawer
      setFlowOpen(false);
    }
  };

  const handleSearch = (value: string) => {
    const lowerCasedValue = value.toLowerCase();
    const filtered = pets.filter((pet) => {
      // Match pet's name
      const petNameMatch = pet.name.toLowerCase().includes(lowerCasedValue);

      // Match breed
      const breedMatch = pet.breed.breed_name
        .toLowerCase()
        .includes(lowerCasedValue);

      // Match owner's full name
      const ownerMatch =
        pet.owners?.some((owner) =>
          `${owner.name} ${owner.surname}`
            .toLowerCase()
            .includes(lowerCasedValue)
        ) || false;

      // Return true if any match
      return petNameMatch || breedMatch || ownerMatch;
    });

    setFilteredPets(filtered);
  };

  return (
    <>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.secondary">Veterinary</Typography>
        <Typography color="text.primary">Pet Directory</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        Pet Directory
      </Typography>

      {/* Single Column Layout with Two "Rows" */}
      <Stack direction="column" spacing={2}>
        {/* Row 1: we'll use flex layout to let the SearchBar expand */}
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={2}
          sx={{ px: 1 }}
        >
          {/* Make the Box around SearchBar grow to fill leftover space */}
          <Box sx={{ flexGrow: 1 }}>
            <SearchBar placeholder="Search for pets" onSearch={handleSearch} />
          </Box>

          <Button
            variant="contained"
            color="primary"
            endIcon={<Pets />}
            sx={{ borderRadius: 12 }}
            onClick={handleAddNew}
          >
            Add Pet
          </Button>
        </Box>

        <Box sx={{ px: 1 }}>
          <PetsTable data={filteredPets} />
        </Box>
      </Stack>

      {/* The multi-step flow (Add Mode) */}
      <AddPetFlow
        open={flowOpen}
        mode="add"
        onClose={handleCloseFlow}
        onSave={handleSavePet}
      />
    </>
  );
};

export default PetDirectoryPage;

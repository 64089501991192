import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, Typography, Box, Stack, Button } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import CustomerTable from '../../../components/CustomerTable';
import { PersonAdd } from '@mui/icons-material';
import { Customer, CustomerBasic } from '../../../types/customerTypes';
import AddCustomerDrawer from '../../../components/AddCustomerDrawer';
import { useAuth } from '../../../contexts/AuthContext';
import { supabase } from '../../../supabaseClient';

const CustomerBookPage: React.FC = () => {
  const { userData } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState<CustomerBasic[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!userData?.vet_company_id) return;

      try {
        // Call the RPC function
        const { data, error } = await supabase.rpc(
          'get_customers_for_vet_company',
          {
            p_vet_company_id: userData.vet_company_id,
          }
        );

        if (error) {
          console.error('Error fetching customers:', error);
          return;
        }

        if (data) {
          // Parse and map the returned JSONB data
          const parsedData = data.map((customer: any) => ({
            id: customer.customer_id,
            name: customer.name,
            surname: customer.surname,
            email: customer.email || '',
            phoneNumber: customer.phone_number || '',
            pets: customer.pets || [],
          }));
          setCustomers(parsedData as CustomerBasic[]);
        }
      } catch (err: any) {
        console.error('Error during fetchCustomers:', err.message);
      }
    };

    fetchCustomers();
  }, [userData?.vet_company_id]);

  const handleAddNew = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);
  const handleSaveCustomer = async (data: Customer) => {
    try {
      // Ensure `vet_company_id` exists
      if (!userData?.vet_company_id) {
        throw new Error('vet_company_id not found in user context');
      }

      // Call the `add_customer` RPC function
      const { data: newCustomerId, error } = await supabase.rpc(
        'add_customer',
        {
          customer_name: data.name,
          customer_surname: data.surname,
          customer_email: data.email,
          phone_country: data.phoneCountry,
          phone_number: data.phoneNumber,
          address: data.address,
          postal_code: data.postalCode,
          city: data.city,
          country: data.country,
          invoice_address: data.invoiceAddress,
          invoice_postal_code: data.invoicePostalCode,
          invoice_city: data.invoiceCity,
          invoice_country: data.invoiceCountry,
          vet_company_id: userData.vet_company_id,
          social_security_number: data.ssn,
        }
      );

      if (error) {
        throw error;
      }

      if (newCustomerId) {
        // Create the new customer object
        const mappedNewCustomer = {
          id: newCustomerId, // The UUID returned from the RPC function
          name: data.name,
          surname: data.surname,
          email: data.email || '',
          phoneNumber: data.phoneNumber || '',
        };

        // Update local state
        setCustomers((prev) => [...prev, mappedNewCustomer]);
      }
    } catch (err: any) {
      console.error('Error adding customer:', err.message);
    } finally {
      // Close the drawer
      setDrawerOpen(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredCustomers = useMemo(() => {
    const lowerCasedValue = searchTerm.toLowerCase();
    return customers.filter((customer) => {
      // Combine the searchable fields
      const fullName = `${customer.name} ${customer.surname}`.toLowerCase();
      const phone = (customer.phoneNumber ?? '').toLowerCase();
      //const ssn = customer.ssn.toLowerCase();
      const email = (customer.email ?? '').toLowerCase();

      // Check if the search term matches any of the fields
      return (
        fullName.includes(lowerCasedValue) ||
        phone.includes(lowerCasedValue) ||
        //ssn.includes(lowerCasedValue) ||
        email.includes(lowerCasedValue)
      );
    });
  }, [searchTerm, customers]);

  return (
    <>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.secondary">Veterinary</Typography>
        <Typography color="text.primary">Customer Book</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        Customer Book
      </Typography>

      {/* Single Column Layout with Two "Rows" */}
      <Stack direction="column" spacing={2}>
        {/* Row 1: we'll use flex layout to let the SearchBar expand */}
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={2}
          sx={{ px: 1 }}
        >
          {/* Make the Box around SearchBar grow to fill leftover space */}
          <Box sx={{ flexGrow: 1 }}>
            <SearchBar
              placeholder="Search for customer"
              onSearch={handleSearch}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            endIcon={<PersonAdd />}
            sx={{ borderRadius: 12 }}
            onClick={handleAddNew}
          >
            Add Customer
          </Button>
        </Box>

        <Box sx={{ px: 1 }}>
          <CustomerTable data={filteredCustomers} />
        </Box>
      </Stack>

      <AddCustomerDrawer
        open={drawerOpen}
        onClose={handleClose}
        mode="add"
        onSubmit={handleSaveCustomer}
      />
    </>
  );
};

export default CustomerBookPage;

import React from 'react';
import { Box, Typography } from '@mui/material';

const DemoPage: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundImage: {
          xs: "url('https://veterinary.rossu.edu/sites/g/files/krcnkv416/files/styles/atge_crop_freeform_md/public/2021-08/about-blog-what-is-a-veterinarian-education-and-training_herom.jpg?h=b930c1be&itok=qaBz_HAt')", // Mobile Image
          md: "url('https://veterinary.rossu.edu/sites/g/files/krcnkv416/files/styles/atge_no_style_lg/public/2021-07/dei-initiatives-access-to-inclusive-veterinary-medicine_hero_1.jpg?itok=zMEMv7vJ')", // Desktop Image
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        Welcome to VetJ - Staging!
      </Typography>
    </Box>
  );
};

export default DemoPage;
